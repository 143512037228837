<template>
    <div>
      <div v-if="skeletonLoading">
        <v-row>
          <v-col cols="12" md="12">
            <v-skeleton-loader
              min-height="600px"
              type="article, article, article, actions"
            >
            </v-skeleton-loader>
          </v-col>
        </v-row>
      </div>
      <div v-if="!skeletonLoading">
        <!--inicio:: condiciones de beneficiarios -->
        <v-row>
          <v-col cols="12" md="6" sm="12" class="pt-1 pb-0">
            <v-subheader class="text-h5 black--text">
              Detalle de los beneficiarios del proyecto
            </v-subheader>
          </v-col>
        </v-row>
        <v-row class="pb-3">
          <v-col cols="12" md="12" sm="12">
            <v-alert outlined color="#1488c2" border="left" class="pb-0">
              <p>
                Los campos marcados con un asterisco (<span
                  color="red"
                  class="red--text"
                  >*</span
                >) son obligatorios
              </p>
            </v-alert>
          </v-col>
        </v-row>
  
        <v-card class="mt-2" outlined>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
                <v-subheader class="text-h6 black--text">
                  Beneficiarios
                </v-subheader>
              </v-col>
            </v-row>
  
            <!--<v-form
              ref="formTipoBeneficiarios"
              v-on:submit.prevent="actualizarTipoBeneficiarios"
              v-model="validFormTipoBeneficiarios"
            >
              <v-row>
                <v-col cols="12" md="7" sm="12" class="pt-6 pb-0">
                  <v-select
                    v-model="beneficiarioDirectoSel"
                    :items="beneficiariosDirectosItems"
                    :loading="false"
                    dense
                    filled
                    class="required"
                    return-object
                    label="¿El proyecto cuenta con beneficiarios directos?"
                    item-text="text"
                    item-value="id"
                    menu-props="offset-y"
                    :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                  ></v-select>
                </v-col>
  
                <v-col
                  cols="12"
                  md="5"
                  sm="12"
                  class="pt-6 pb-0"
                  v-if="
                    (tipoUsuario != `int` && !seccionesBloqueadas) ||
                      tipoUsuario === `int`
                  "
                >
                  <v-btn
                    color="light-blue-502"
                    class="white--text mb-2 float-right"
                    type="submit"
                    medium
                    :elevation="0"
                    :disabled="
                      !validFormTipoBeneficiarios ||
                        beneficiarioDirectoSel.id === 0
                    "
                    :loading="btnRegistroTipoBeneficiariosLoading"
                  >
                    Guardar información
                  </v-btn>
                </v-col>
              </v-row>
            </v-form> -->
  
            <v-row>
              <v-col cols="12" class="pt-0">
                <v-divider class="mt-0 pt-0"></v-divider>
              </v-col>
  
              <v-col
                cols="12"
                md="12"
                sm="12"
                class="pt-6 pb-0"
                v-if="habilitarRegistroBeneficiarios"
              >
                <v-form
                  ref="formBeneficiarios"
                  v-on:submit.prevent="registrarBeneficiario"
                  v-model="validFormBeneficiarios"
                  v-if="!seccionesBloqueadas && !confirmacion"
                >
                  <v-row>
                    <!--  <v-col cols="12">
                                
                              </v-col>
   -->
  
                    <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                      <v-select
                        @change="
                          obtenerMunicipiosPorDepartamento(
                            datosBeneficiario.departamentosId
                          )
                        "
                        v-model="datosBeneficiario.departamentosId"
                        :items="departamentos"
                        :loading="departamentosLoading"
                        dense
                        class="required"
                        filled
                        label="Departamento"
                        item-text="departamento"
                        item-value="id"
                        :no-data-text="
                          departamentos != null
                            ? 'Seleccione un departamento'
                            : 'No se han encontrado departamentos'
                        "
                        :rules="[selectRequired('departamento')]"
                        menu-props="offset-y"
                      ></v-select>
                    </v-col>
  
                    <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                      <v-select
                        v-model="datosBeneficiario.municipiosId"
                        :items="municipios"
                        :clearable="true"
                        :loading="ddMunicipiosLoading"
                        dense
                        filled
                        label="Municipio"
                        item-text="municipio"
                        item-value="id"
                        :no-data-text="
                          municipios != null
                            ? 'Seleccione un municipio'
                            : 'No se han encontrado municipios'
                        "
                        menu-props="offset-y"
                      ></v-select>
                    </v-col>
  
                    <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                      <v-text-field
                        dense
                        filled
                        autocomplete="off"
                        class=""
                        color="blue-grey lighten-2"
                        v-model="datosBeneficiario.aldeaCaserio"
                        label="Aldea, caserío u otro"
                        :rules="[maxLength('aldea caserío y otro', 250)]"
                        maxlength="250"
                        :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                        <v-select
                        v-model="datosBeneficiario.descripcionBeneficiarios"
                        :items="tiposBeneficiariosItems"
                        :clearable="false"
                        :loading="false"
                        dense
                        filled
                        label="Tipo de Beneficiario"
                        item-text="tipo"
                        item-value="id"
                        :rules="[selectRequired('tipo de beneficiario')]"
                        :no-data-text="
                          municipios != null
                            ? 'Seleccione un tipo de beneficiario'
                            : 'No se han encontrado tipos de beneficiarios'
                        "
                        menu-props="offset-y"
                      ></v-select>
                      
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4" sm="4" xs="12" class="pt-0 pb-0">
                        <v-text-field
                            dense
                            filled
                            autocomplete="off"
                            class=""
                            color="blue-grey lighten-2"
                            v-model="beneficiarioLatitud"
                            label="Latitud"
                            :rules="[maxLength('latitud', 25), beneficiarioLatitud.length > 0 ? latitude('latitud') : true]"
                            maxlength="25"
                        ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4" sm="4" xs="12" class="pt-0 pb-0">
                        <v-text-field
                            dense
                            filled
                            autocomplete="off"
                            class=""
                            color="blue-grey lighten-2"
                            v-model="beneficiarioLongitud"
                            label="Longitud"
                            :rules="[maxLength('longitud', 25), beneficiarioLongitud.length > 0 ? longitude('longitud') : true]"
                            maxlength="25"
                        ></v-text-field>
                    </v-col>
                    </v-row>
                  <v-row>
                    <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                      <v-select
                        v-model="generoSeleccionado"
                        :items="generos"
                        :loading="false"
                        dense
                        filled
                        class="required"
                        return-object
                        :rules="[selectRequired('Género beneficiado')]"
                        label="Género Beneficiado"
                        item-text="genero"
                        item-value="id"
                        menu-props="offset-y"
                        :disabled="tipoUsuario == `ext` && seccionesBloqueadas"

                      ></v-select>
                    </v-col>
  
                    <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                      <v-select
                        v-model="datosBeneficiario.areasBeneficiadasId"
                        :items="areasBeneficiadas"
                        :loading="false"
                        dense
                        filled
                        class="required"
                        :rules="[selectRequired('área beneficiada')]"
                        label="Área Beneficiada"
                        item-text="nombreArea"
                        item-value="id"
                        menu-props="offset-y"
                        :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                      ></v-select>
                    </v-col>
  
                    <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                      <v-select
                        v-model="datosBeneficiario.etniasId"
                        :items="etnias"
                        :loading="false"
                        dense
                        filled
                        class="required"
                        :rules="[selectRequired('etnia')]"
                        label="Etnia"
                        item-text="nombreEtnia"
                        item-value="id"
                        menu-props="offset-y"
                        :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                      ></v-select>
                    </v-col>
  
                    <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                      <v-select
                        v-model="datosBeneficiario.rangosEdadId"
                        :items="rangosEdad"
                        :loading="false"
                        dense
                        filled
                        class="required"
                        :rules="[selectRequired('rango de edad')]"
                        label="Rango de edad"
                        item-text="rango"
                        item-value="id"
                        menu-props="offset-y"
                        :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                      ></v-select>
                    </v-col>
  
                    <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                      <v-text-field
                        dense
                        filled
                        autocomplete="off"
                        class="required"
                        color="blue-grey lighten-2"
                        hint="Detalle el número de beneficiarios para este registro"
                        v-model="datosBeneficiario.totalBeneficiarios"
                        label="Número de beneficiarios"
                        :rules="[
                          required('número de desembolsos'),
                          onlyInteger('número de desembolsos'),
                          minLength('número de beneficiarios', 1),
                          minNumber('número de beneficiarios', 0)
                        ]"
                        maxlength="11"
                        v-mask="'###########'"
                        :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                      ></v-text-field>
                    </v-col>
  
                    <v-col
                      cols="12"
                      md="4"
                      sm="12"
                      class="pt-0"
                      v-if="
                        (tipoUsuario != `int` && !seccionesBloqueadas) ||
                          tipoUsuario === `int`
                      "
                    >
                      <v-btn
                        color="light-blue-502"
                        class="white--text mb-2 float-right mt-2"
                        type="submit"
                        :elevation="0"
                        :disabled="!validFormBeneficiarios"
                        :loading="btnRegistroBeneficiarioLoading"
                      >
                        Guardar información
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
  
              <!--inicio:: tabla beneficiarios -->
              <v-col
                cols="12"
                md="12"
                sm="12"
                class="pt-6"
                v-if="habilitarRegistroBeneficiarios"
              >
                <v-data-table
                  class="elevation-0"
                  :headers="headersBeneficiarios"
                  :items="beneficiarios"
                  :loading="tableBeneficiariosLoading"
                  loading-text="Cargando..."
                  :header-props="{
                    orderByText: 'Ordenar por'
                  }"
                  :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-page-first',
                    lastIcon: 'mdi-page-last',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right',
                    'items-per-page-text': 'Registros por página',
                    pageText: '{0}-{1} de {2}'
                  }"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.departamento }}</td>
                      <td>{{ item.municipio }}</td>
                      <td>{{ item.aldeaCaserio }}</td>
                      <td>{{ item.descripcionBeneficiarios == `D` ? `Directo` : item.descripcionBeneficiarios == `I` ? `Indirecto`: `` }}</td>
                      <td>{{ item.genero }}</td>
                      <td>{{ item.nombreArea }}</td>
                      <td>{{ item.nombreEtnia }}</td>
                      <td>{{ item.rango }}</td>
                      <td class="text-right">{{ item.totalBeneficiarios }}</td>
                      <td>
                        <v-btn
                          v-if="!seccionesBloqueadas"
                          class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                          small
                          depressed
                          :disabled="btnEliminarBeneficiarioDisabled"
                          color="blue-grey lighten-5"
                          @click="obtenerDatosBeneficiario(item)"
                        >
                          <v-icon left>mdi-file-document-edit-outline</v-icon>
                          Actualizar
                        </v-btn>
                        <v-btn
                          v-if="!seccionesBloqueadas"
                          class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                          small
                          depressed
                          :disabled="btnEliminarBeneficiarioDisabled"
                          color="blue-grey lighten-5"
                          @click="eliminarBeneficiarioDirecto(item.id)"
                        >
                          <v-icon left>mdi-delete</v-icon> Eliminar
                        </v-btn>
                      </td>
                    </tr>
                  </template>
  
                  <template v-slot:no-data>
                    <p class="text-h7">
                      <v-icon left>mdi-alert</v-icon> No existen beneficiarios
                      registrados
                    </p>
                  </template>
                </v-data-table>
  
                <v-row class="pt-5 pb-8">
                  <v-col cols="12" md="3" sm="6" xs="6" class="pb-0">
                    <v-list-item two-line>
                      <v-list-item-content class="pb-0 pt-0">
                        <v-list-item-title class="text-h6 font-weight-bold"
                          >Masculino</v-list-item-title
                        >
                        <v-list-item-subtitle class="text-h6">{{
                          totalMasculino
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
  
                  <v-col cols="12" md="3" sm="6" xs="6" class="pb-0">
                    <v-list-item two-line>
                      <v-list-item-content class="pb-0 pt-0">
                        <v-list-item-title class="text-h6"
                          >Femenino</v-list-item-title
                        >
                        <v-list-item-subtitle class="text-h6">{{
                          totalFemenino
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
  
                  <v-col cols="12" md="3" sm="6" xs="6" class="pb-0">
                    <v-list-item two-line>
                      <v-list-item-content class="pb-0 pt-0">
                        <v-list-item-title class="text-h6"
                          >Otros</v-list-item-title
                        >
                        <v-list-item-subtitle class="text-h6">{{
                          totalOtros
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
  
                  <v-col cols="12" md="3" sm="6" xs="6" class="pb-0">
                    <v-list-item two-line>
                      <v-list-item-content class="pb-0 pt-0">
                        <v-list-item-title class="text-h6"
                          >Total</v-list-item-title
                        >
                        <v-list-item-subtitle class="text-h6">{{
                          totalBeneficiarios
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-col>
              <!--fin:: tabla beneficiarios -->
            </v-row>
          </v-card-text>
        </v-card>
        <!--fin:: condiciones de beneficiarios -->
  
        <!--inicio:: condiciones de instituciones beneficiarias -->
        <v-card class="mt-6 d-none" outlined>
          <v-card-text>
            <!-- <v-row>
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-subheader class="text-h6 black--text">
                  Detalle de las instituciones beneficiarias
                </v-subheader>
              </v-col>
            </v-row> -->
  
            <!--<v-form
              ref="formInstitucionesBeneficiarias"
              v-on:submit.prevent="actualizarCuentaInstitucionesBeneficiarias"
              v-model="validFormCuentaInstituciones"
            >
              <v-row>
                <v-col cols="12" md="7" sm="12" class="pt-6 pb-0">
                  <v-select
                    v-model="cuentaInstitucionesBeneficiariasSel"
                    :items="institucionesBeneficiariasItems"
                    :loading="false"
                    dense
                    filled
                    class="required"
                    return-object
                    label="¿El proyecto cuenta con instituciones beneficiarias?"
                    item-text="text"
                    item-value="id"
                    menu-props="offset-y"
                    :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                  ></v-select>
                </v-col>
  
                <v-col
                  cols="12"
                  md="5"
                  sm="12"
                  class="pt-6 pb-0"
                  v-if="
                    (tipoUsuario != `int` && !seccionesBloqueadas) ||
                      tipoUsuario === `int`
                  "
                >
                  <v-btn
                    color="light-blue-502"
                    class="white--text mb-2 float-right"
                    type="submit"
                    medium
                    :elevation="0"
                    :disabled="
                      !validFormCuentaInstituciones ||
                        cuentaInstitucionesBeneficiariasSel.id === 0
                    "
                    :loading="btnRegistroCuentaInstitucionesLoading"
                  >
                    Guardar información
                  </v-btn>
                </v-col>
              </v-row>
            </v-form> -->
  
            <!-- <v-row>
              <v-col cols="12" md="12">
                <v-divider class="mt-0 pt-0"></v-divider>
              </v-col>
            </v-row> -->
  
            <v-row v-if="habilitarRegistroInstituciones" class="mt-4 d-none">
              <!-- <v-col cols="12" md="12" sm="12" class="pt-6 pb-0">
                <v-form
                  ref="formInstitucionesBeneficiarias"
                  v-on:submit.prevent="registrarInstitucionBeneficiaria"
                  v-model="validFormBenefifiariosInstitucion"
                  v-if="
                    (tipoUsuario != `int` && !seccionesBloqueadas) ||
                      tipoUsuario === `int`
                  "
                >
                  <v-row>
                    <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                      <v-autocomplete
                        v-model="datosInstitucionBeneficiaria.ENTIDAD_ID"
                        :items="instituciones"
                        :loading="ddInstitucionesLoading"
                        dense
                        filled
                        class="required"
                        :rules="[selectRequired('institución beneficiada')]"
                        label="Institución"
                        item-text="nombreInstitucion"
                        item-value="id"
                        menu-props="offset-y"
                        :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                      ></v-autocomplete>
                    </v-col>
  
                    <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                      <v-select
                        v-model="datosInstitucionBeneficiaria.tiposIngresoId"
                        :items="tiposIngreso"
                        :loading="false"
                        dense
                        filled
                        class="required"
                        :rules="[selectRequired('tipo de ingreso')]"
                        label="Rol (Tipo de ingreso)"
                        item-text="nombreTipoIngreso"
                        item-value="id"
                        menu-props="offset-y"
                        :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                      ></v-select>
                    </v-col>
  
                    <v-col
                      cols="12"
                      md="12"
                      sm="12"
                      class="pt-0"
                      v-if="
                        (tipoUsuario != `int` && !seccionesBloqueadas) ||
                          tipoUsuario === `int`
                      "
                    >
                      <v-btn
                        color="light-blue-502"
                        class="white--text mb-2 float-right"
                        type="submit"
                        :elevation="0"
                        :disabled="!validFormBenefifiariosInstitucion"
                        :loading="btnRegistroBeneficiarioInstitucionLoading"
                      >
                        Agregar institución
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col> -->
  
              <!--inicio:: tabla beneficiarios -->
              <!-- <v-col
                cols="12"
                md="12"
                sm="12"
                class="pt-6"
                v-if="habilitarRegistroInstituciones"
              >
                <v-data-table
                  class="elevation-0"
                  :headers="headersInstitucionesBeneficiarias"
                  :items="institucionesBeneficiarias"
                  :loading="tableBeneficiariosLoading"
                  loading-text="Cargando..."
                  :header-props="{
                    orderByText: 'Ordenar por'
                  }"
                  :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-page-first',
                    lastIcon: 'mdi-page-last',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right',
                    'items-per-page-text': 'Registros por página',
                    pageText: '{0}-{1} de {2}'
                  }"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.nombre }}</td>
                      <td>{{ item.nombreTipoIngreso }}</td>
                      <td>
                        <v-btn
                          v-if="!seccionesBloqueadas"
                          class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                          small
                          depressed
                          color="blue-grey lighten-5"
                          :disabled="btnEliminarInstitucionDisabled"
                          @click="eliminarInstitucionBeneficiaria(item.id)"
                        >
                          <v-icon left>mdi-delete</v-icon> Eliminar
                        </v-btn>
                      </td>
                    </tr>
                  </template>
  
                  <template v-slot:no-data>
                    <p class="text-h7">
                      <v-icon left>mdi-alert</v-icon> No existe información
                      registrada de distribución de montos
                    </p>
                  </template>
                </v-data-table>
              </v-col> -->
              <!--fin:: tabla beneficiarios -->
            </v-row>
          </v-card-text>
        </v-card>
        <!--fin:: condiciones de instituciones beneficiarias -->
      </div>
  
      <SnackAlert ref="snackalert"></SnackAlert>
      <!-- Fin:: Snack alert-->
  
      <!--Dialog loader -->
      <DialogLoader
        :dialogVisible="dialogLoaderVisible"
        :text="dialogLoaderText"
      ></DialogLoader>
      <!---->
    </div>
  </template>
  
  <script>
  import DialogLoader from "@/view/content/DialogLoader";
  import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
  import validations from "@/core/untils/validations.js";
  import { OBTENER_PROYECTO_ACL } from "@/core/services/store/proyectosactoreslocales/proyectoactoreslocales.module";
  import { OBTENER_ITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";
  import { OBTENER_DEPARTAMENTOS_PAIS } from "@/core/services/store/catalogos/departamentos/departamento.module";
  import { OBTENER_MUNICIPIOS_DEPARTAMENTO } from "@/core/services/store/catalogos/municipios/municipio.module";
  import {
    //ACTUALIZAR_CUENTA_BENEFICIARIOS_DIRECTOS_CNS,
    //ACTUALIZAR_CUENTA_INSTITUCIONES_BENEFICIARIAS_CNS,
    OBTENER_BENEFICIARIOS_ACL,
    REGISTRAR_BENEFICIARIO_ACL,
    ACTUALIZAR_BENEFICIARIO_ACL,
    ELIMINAR_BENEFICIARIO_ACL
  } from "@/core/services/store/proyectosactoreslocales/beneficiarios/beneficiarioacl.module";
  import {
    OBTENER_BENEFICIARIOS_INSTITUCION,
    REGISTRAR_BENEFICIARIO_INSTITUCION,
    ELIMINAR_INSTITUCION_BENEFICIARIA_CNS
  } from "@/core/services/store/proyectoscns/beneficiarios/beneficiarioinstitucion.module";
  //import { OBTENER_INSTITUCIONES } from "@/core/services/store/instituciones/institucion.module";
  
  export default {
    name: "SeccionBeneficiariosProyectoActoresLocales",
    props: ["id", "tipo", "tipoUsuario", "confirmacion"], //Tipo de usuario puede ser ext (externo) o int (interno administrador), confirmacion (si se van a bloquear los elementos porque solo se van a visualizar)
    data() {
      return {
        cardDatosLoading: false,
        seccionesBloqueadas: false,
        skeletonLoading: false,
        accionBeneficiarios: 1,
        validFormTipoBeneficiarios: false,
        validFormCuentaInstituciones: false,
        btnRegistroTipoBeneficiariosLoading: false,
        tableBeneficiariosLoading: false,
        tableBeneficiariosInstitucionLoading: false,
        dialogLoaderVisible: false,
        dialogLoaderText: "",
        validFormBeneficiarios: false,
        validFormBenefifiariosInstitucion: false,
        btnRegistroBeneficiarioLoading: false,
        btnRegistroBeneficiarioInstitucionLoading: false,
        municipioSeleccionado: {},
        municipios: [],
        ddMunicipiosLoading: false,
        departamentosLoading: false,
        departamentos: [],
        datosCuentaInstitucionesBeneficiarias: {
          id: this.id,
          cuentaInstitucionesBeneficiarias: 0,
          usuarioActualizacoin: "admin"
        },
        btnRegistroCuentaInstitucionesLoading: false,
  
        beneficiariosDirectosItems: [
          { id: 0, text: "Seleccione" },
          { id: 1, text: "Si" },
          { id: 2, text: "No" }
        ],
        beneficiarioDirectoSel: {},
        cuentaInstitucionesBeneficiariasSel: {},
        institucionesBeneficiariasItems: [
          { id: 0, text: "Seleccione" },
          { id: 1, text: "Si" },
          { id: 2, text: "No" }
        ],
        generos: [],
        generoSeleccionado: {},
        areasBeneficiadas: [],
        etnias: [],
        rangosEdad: [],
        instituciones: [],
        tiposIngreso: [],
        beneficiarios: [],
        ddInstitucionesLoading: false,
        institucionesBeneficiarias: [],
        datosTipoBeneficiario: {
          id: 0,
          beneficiariosDirectos: 0,
          usuarioActualizacoin: "admin"
        },
        datosItem: {
          sectoresBeneficiadosId: 0
        },
  
        datosBeneficiario: {
          proyectosActoresLocalesId: this.id,
          departamentosId: 0,
          municipiosId: 0,
          aldeaCaserio: "",
          descripcionBeneficiarios: "",
          genero: "",
          areasBeneficiadasId: 0,
          etniasId: 0,
          rangosEdadId: 0,
          totalBeneficiarios: "",
          estadosId: 1,
          usuarioCreacion: "admin",
          latitud: null,
          longitud: null,
        },
        tiposBeneficiariosItems: [
            {id: "", tipo: "Seleccione"},
            {id: "D", tipo: "Directo"},
            {id: "I", tipo: "Indirecto"}
        ],
        beneficiarioLatitud: "",
        beneficiarioLongitud: "",
  
        datosInstitucionBeneficiaria: {
          tipoProyecto: 1,
          proyectosActoresLocalesId: this.id,
          ENTIDAD_ID: 0,
          tiposIngresoId: 0,
          estadosId: 1,
          usuarioCreacion: "admin"
        },
        habilitarRegistroInstituciones: false,
        habilitarRegistroBeneficiarios: true,
        btnEliminarBeneficiarioDisabled: false,
        btnEliminarInstitucionDisabled: false,
        totalMasculino: 0,
        totalFemenino: 0,
        totalOtros: 0,
        totalBeneficiarios: 0,
        datosProyecto: {},
        ...validations
      };
    },
  
    components: {
      DialogLoader,
      SnackAlert
    },
  
    methods: {
      resetItems(tipo, proyectoId) {
        this.id = proyectoId;
        this.datosBeneficiario.proyectosActoresLocalesId = this.id;
        this.datosProyecto = {};
        this.seccionesBloqueadas = false;
        if (tipo === 2) {
          this.skeletonLoading = true;
        }
        
        this.habilitarRegistroInstituciones = false;
        this.habilitarRegistroBeneficiarios = true;
        this.beneficiarioDirectoSel = {
          id: 0,
          text: "Seleccione"
        };
        this.cuentaInstitucionesBeneficiariasSel = {
          id: 0,
          text: "Seleccione"
        };
  
        this.resetForm(1);
        this.resetFormInstitucion();
        this.obtenerDepartamentos();
        if (tipo == 2) {
          this.obtenerDatosSeccion(this.id);
        }
        
        this.obtenerGeneros();
        this.obtenerAreasBeneficiadas();
        this.obtenerEtnias();
        this.obtenerRangosEdad();
        //this.obtenerInstituciones();
        this.obtenerTiposIngreso();
        this.obtenerBeneficiarios(this.id);
        //this.obtenerBeneficiariosInstitucion(this.id);
        
      },
  
      resetForm(tipo) {
        this.accionBeneficiarios = 1;
        //this.municipioSeleccionado = {};
        //this.municipios = [];
        this.generoSeleccionado = {};
  
        this.datosBeneficiario = {
          proyectosActoresLocalesId: this.id,
          departamentosId: this.datosBeneficiario.departamentosId,
          municipiosId: this.datosBeneficiario.municipiosId,
          aldeaCaserio: this.datosBeneficiario.aldeaCaserio,
          descripcionBeneficiarios: "",
          genero: "",
          areasBeneficiadasId: 0,
          etniasId: 0,
          rangosEdadId: 0,
          totalBeneficiarios: "",
          estadosId: 1,
          usuarioCreacion: "admin",
          latitud: null,
          longitud: null,
        };
        this.beneficiarioLatitud = "";
        this.beneficiarioLongitud = "";
  
        if (tipo === 1) {
          this.datosBeneficiario.departamentosId = null;
          this.datosBeneficiario.municipiosId = null;
          this.datosBeneficiario.aldeaCaserio = "";
        }
      },
      resetFormInstitucion() {
        this.datosInstitucionBeneficiaria = {
          tipoProyecto: 1,
          proyectosActoresLocalesId: this.id,
          ENTIDAD_ID: 0,
          tiposIngresoId: 0,
          estadosId: 1,
          usuarioCreacion: "admin"
        };
      },
  
      //Obtener los generos
      async obtenerGeneros() {
        this.generos = [
          { id: 0, genero: "Seleccione", codigo: "N/A" },
          { id: 1, genero: "Femenino", codigo: "F" },
          { id: 2, genero: "Masculino", codigo: "M" },
          { id: 3, genero: "Otros", codigo: "O" }
        ];
  
        /* this.cardDatosLoading = true;
             
                this.$store
                    .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'Generos'})
                    .then(res => {
                        if (res.status === 200) {
                            this.generos = res.data;
                            this.cardDatosLoading = false;
                        }
                    })
                    .catch(() => {
                        this.cardDatosLoading = false;
                    }); */
      },
  
      //Obtener areas beneficiadas
      async obtenerAreasBeneficiadas() {
        this.areasBeneficiadas = [];
  
        this.cardDatosLoading = true;
  
        this.$store
          .dispatch(OBTENER_ITEMS_CATALOGO, {
            endpoint: "AreasBeneficiadas/all/1"
          })
          .then(res => {
            if (res.status === 200) {
              this.areasBeneficiadas = res.data;
              this.cardDatosLoading = false;
            }
          })
          .catch(() => {
            this.cardDatosLoading = false;
          });
      },
  
      //Obtener etnias
      async obtenerEtnias() {
        this.etnias = [];
  
        this.cardDatosLoading = true;
  
        this.$store
          .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "Etnias/all/1" })
          .then(res => {
            if (res.status === 200) {
              this.etnias = res.data;
              this.cardDatosLoading = false;
            }
          })
          .catch(() => {
            this.cardDatosLoading = false;
          });
      },
  
      //Obtener rangos de edad
      async obtenerRangosEdad() {
        this.rangosEdad = [];
  
        this.cardDatosLoading = true;
  
        this.$store
          .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "RangosEdad/all/1" })
          .then(res => {
            if (res.status === 200) {
              this.rangosEdad = res.data;
              this.cardDatosLoading = false;
            }
          })
          .catch(() => {
            this.cardDatosLoading = false;
          });
      },
  
      /// Obtener instituciones
      /*async obtenerInstituciones() {
            this.instituciones = [];
            this.tableLoading = true;
    
            this.$store
                .dispatch(OBTENER_INSTITUCIONES, {estadoId: 1, nivelInstitucionId: 0, tipoInstitucionId: 1})
                .then(() => {
                this.instituciones = this.$store.state.institucion.instituciones;
               
                this.tableLoading = false;
                })
                .catch(() => {
                this.tableLoading = false;
                });
            },*/
  
      //Obtener departamentos
      async obtenerDepartamentos() {
        this.departamentosLoading = true;
        //this.departamentosActividadLoading = true;
        this.departamentos = [];
        //this.departamentosActividad = [];
        await this.$store
          .dispatch(OBTENER_DEPARTAMENTOS_PAIS, 94) //94 representa al país 94 (Guatemala)
          .then(res => {
            if (res.status === 200) {
              this.departamentos = res.data;
              //this.departamentosActividad = res.data;
            }
            this.departamentosLoading = false;
            // this.departamentosActividadLoading=false;
          })
          .catch(() => {
            this.departamentos = [];
            //this.departamentosActividad = [];
            this.departamentosLoading = false;
            //this.departamentosActividadLoading=false;
          });
      },
  
      //Obtener municipios por departamento
      async obtenerMunicipiosPorDepartamento(departamentosId) {
        this.datosBeneficiario.municipiosId = null;
        this.ddMunicipiosLoading = true;
  
        await this.$store
          .dispatch(OBTENER_MUNICIPIOS_DEPARTAMENTO, departamentosId) //1 representa al país 1 (Guatemala)
          .then(res => {
            if (res.status === 200) {
              this.municipios = res.data;
            }
            this.ddMunicipiosLoading = false;
          })
          .catch(() => {
            this.ddMunicipiosLoading = false;
          });
      },
  
      //Obtener las instituciones
      async obtenerInstituciones() {
        this.instituciones = [];
        this.ddInstitucionesLoading = true;
  
        this.instituciones = [];
        await this.$store
          .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: `Entidades/all/1` })
          .then(res => {
            if (res.status == 200) {
              this.instituciones = res.data;
            }
            this.ddInstitucionesLoading = false;
          })
          .catch(() => {
            this.ddInstitucionesLoading = false;
            this.ddInstitucionesLoading = false;
            this.instituciones = [];
          });
      },
  
      //Obtener tipos de ingreso
      async obtenerTiposIngreso() {
        this.tiposIngreso = [];
  
        this.cardDatosLoading = true;
  
        this.$store
          .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "TiposIngreso/all/1" })
          .then(res => {
            if (res.status === 200) {
              this.tiposIngreso = res.data;
              this.cardDatosLoading = false;
            }
          })
          .catch(() => {
            this.cardDatosLoading = false;
          });
      },
  
      //Obtener la información de cobertura
      async obtenerDatosSeccion(proyectoId) {
        this.datosItem = {};
        //this.cardDatosLoading = true;
  
        this.$store
          .dispatch(OBTENER_PROYECTO_ACL, {
            id: proyectoId
          })
          .then(res => {
            if (res.status === 200) {
              this.datosItem = res.data;
  
              //Bloquear las secciones si es usuario externo y si el proyecto no está en estado registro
              if (this.datosItem.estadoProyectoId != 1) {
                this.seccionesBloqueadas = true;
              } else {
                this.seccionesBloqueadas = false;
              }
  
              if (this.confirmacion) {
                this.seccionesBloqueadas = true;
              }
  
              /* if (this.datosItem.cuentaInstitucionesBeneficiarias) {
                this.cuentaInstitucionesBeneficiariasSel = this.institucionesBeneficiariasItems.find(
                  item =>
                    item.id === this.datosItem.cuentaInstitucionesBeneficiarias
                );
                if (this.datosItem.cuentaInstitucionesBeneficiarias === 1) {
                  this.habilitarRegistroInstituciones = true;
                }
              } else {
                this.cuentaInstitucionesBeneficiariasSel = {
                  id: 0,
                  text: "Seleccione"
                };
              } */
  
              //  console.log(this.datosItem.beneficiariosDirectos)
  
              /* if (this.datosItem.beneficiariosDirectos) {
                this.beneficiarioDirectoSel = this.beneficiariosDirectosItems.find(
                  item => item.id === this.datosItem.beneficiariosDirectos
                );
                if (this.datosItem.beneficiariosDirectos === 1) {
                  this.habilitarRegistroBeneficiarios = true;
                }
              } else {
                this.beneficiarioDirectoSel = {
                  id: 0,
                  text: "Seleccione"
                };
              } */
            }
            //this.cardDatosLoading = false;
            this.skeletonLoading = false;
            
            //this.condicionDesembolsos.id = this.datosItem.condicionesDesembolsos;
          })
          .catch(() => {
            this.skeletonLoading = false;
            this.cardDatosLoading = false;
          });

      },
  
      //Obtener los beneficiarios
      async obtenerBeneficiarios(proyectoId) {
        //this.skeletonLoading = true;
        this.beneficiarios = [];
        this.tableBeneficiariosLoading = true;
        this.totalMasculino = 0;
        this.totalFemenino = 0;
        this.totalOtros = 0;
        this.totalBeneficiarios = 0;
        this.$store
          .dispatch(OBTENER_BENEFICIARIOS_ACL, {
            estadoId: 1,
            tipoProyecto: 1,
            proyectoId: proyectoId
          })
          .then(res => {
            if (res.status === 200) {
              this.beneficiarios = res.data;
  
              this.totalMasculino = this.beneficiarios
                .filter(({ genero }) => genero === "M")
                .reduce(
                  (a, b) =>
                    parseFloat(a) + (parseFloat(b["totalBeneficiarios"]) || 0),
                  0
                );
  
              this.totalFemenino = this.beneficiarios
                .filter(({ genero }) => genero === "F")
                .reduce(
                  (a, b) =>
                    parseFloat(a) + (parseFloat(b["totalBeneficiarios"]) || 0),
                  0
                );
              this.totalOtros = this.beneficiarios
                .filter(({ genero }) => genero === "O")
                .reduce(
                  (a, b) =>
                    parseFloat(a) + (parseFloat(b["totalBeneficiarios"]) || 0),
                  0
                );
  
              this.totalBeneficiarios = this.beneficiarios.reduce(
                (a, b) =>
                  parseFloat(a) + (parseFloat(b["totalBeneficiarios"]) || 0),
                0
              );
            }
            this.tableBeneficiariosLoading = false;
            this.skeletonLoading = false;
          })
          .catch(() => {
            this.tableBeneficiariosLoading = false;
            this.skeletonLoading = false;
          });
      },
  
      //Obtener los beneficiarios de instituciones
      async obtenerBeneficiariosInstitucion(proyectoId) {
        this.institucionesBeneficiarias = [];
        this.tableBeneficiariosInstitucionLoading = true;
  
        this.$store
          .dispatch(OBTENER_BENEFICIARIOS_INSTITUCION, {
            estadoId: 1,
            tipoProyecto: 1,
            proyectoId: proyectoId
          })
          .then(res => {
            if (res.status === 200) {
              this.institucionesBeneficiarias = res.data;
            }
            this.tableBeneficiariosInstitucionLoading = false;
          })
          .catch(() => {
            this.institucionesBeneficiarias = [];
            this.tableBeneficiariosInstitucionLoading = false;
          });
      },
  
      //Actualizar el tipo de beneficiarios con los que cuenta el proyecto
     /*  async actualizarTipoBeneficiarios() {
        this.btnRegistroTipoBeneficiariosLoading = true;
  
        this.datosTipoBeneficiario.id = this.id;
        this.datosTipoBeneficiario.beneficiariosDirectos = this.beneficiarioDirectoSel.id;
        this.datosTipoBeneficiario.usuarioActualizacoin = "admin";
  
        await this.$store
          .dispatch(ACTUALIZAR_CUENTA_BENEFICIARIOS_DIRECTOS_CNS, {
            datos: this.datosTipoBeneficiario
          })
          .then(res => {
            //this.dialogLoaderVisible = false;
            if (res.status === 200) {
              this.$refs.snackalert.SnackbarShow(
                "success",
                "Mensaje",
                res.message
              );
               this.beneficiarioDirectoSel.id === 1
                ? (this.habilitarRegistroBeneficiarios = true)
                : (this.habilitarRegistroBeneficiarios = false);
              this.obtenerBeneficiarios(this.id);
            } else {
              this.$refs.snackalert.SnackbarShow(
                "warning",
                "Alerta",
                res.message
              );
            }
            this.btnRegistroTipoBeneficiariosLoading = false;
          })
          .catch(() => {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              `Ha ocurrido un error durante el registro.`
            );
            this.btnRegistroTipoBeneficiariosLoading = false;
          });
      }, */
  
      //Actualizar el campo "El proyecto cuenta con instituciones beneficiarias"
      /* async actualizarCuentaInstitucionesBeneficiarias() {
        this.btnRegistroCuentaInstitucionesLoading = true;
  
        this.datosCuentaInstitucionesBeneficiarias.id = this.id;
        this.datosCuentaInstitucionesBeneficiarias.cuentaInstitucionesBeneficiarias = this.cuentaInstitucionesBeneficiariasSel.id;
        this.datosCuentaInstitucionesBeneficiarias.usuarioActualizacoin = "admin";
  
        await this.$store
          .dispatch(ACTUALIZAR_CUENTA_INSTITUCIONES_BENEFICIARIAS_CNS, {
            datos: this.datosCuentaInstitucionesBeneficiarias
          })
          .then(res => {
            //this.dialogLoaderVisible = false;
            if (res.status === 200) {
              this.$refs.snackalert.SnackbarShow(
                "success",
                "Mensaje",
                res.message
              );
              this.cuentaInstitucionesBeneficiariasSel.id === 1
                ? (this.habilitarRegistroInstituciones = true)
                : (this.habilitarRegistroInstituciones = false);
              this.obtenerBeneficiariosInstitucion(this.id);
            } else {
              this.$refs.snackalert.SnackbarShow(
                "warning",
                "Alerta",
                res.message
              );
            }
            this.btnRegistroCuentaInstitucionesLoading = false;
          })
          .catch(() => {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              `Ha ocurrido un error durante el registro.`
            );
            this.btnRegistroCuentaInstitucionesLoading = false;
          });
      }, */
  
      async registrarBeneficiario() {
        this.btnRegistroBeneficiarioLoading = true;
        this.datosBeneficiario.proyectosActoresLocalesId = this.id;
  
        this.datosBeneficiario.genero = this.generoSeleccionado.codigo;

        if(this.beneficiarioLatitud == "")
            this.datosBeneficiario.latitud = null;
        else 
            this.datosBeneficiario.latitud = this.beneficiarioLatitud;

        if(this.beneficiarioLongitud == "")
            this.datosBeneficiario.longitud = null;
        else
            this.datosBeneficiario.longitud = this.beneficiarioLongitud;
  
        let dispatch = REGISTRAR_BENEFICIARIO_ACL;
  
        if (this.accionBeneficiarios === 2){
          dispatch = ACTUALIZAR_BENEFICIARIO_ACL;
        }
  
        await this.$store
          .dispatch(dispatch, { datos: this.datosBeneficiario })
          .then(res => {
            this.btnLoading = false;
            //this.dialogLoaderVisible = false;
            if (res.status === 200) {
              this.$refs.snackalert.SnackbarShow(
                "success",
                "Mensaje",
                res.message
              );
              this.obtenerBeneficiarios(this.id);
              this.resetForm(2);
            } else {
              this.$refs.snackalert.SnackbarShow(
                "warning",
                "Alerta",
                res.message
              );
            }
            this.btnRegistroBeneficiarioLoading = false;
          })
          .catch(() => {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              `Ha ocurrido un error durante el registro.`
            );
          });
        this.btnRegistroBeneficiarioLoading = false;
      },
  
      //Registrar el beneficiario para institucion
      async registrarInstitucionBeneficiaria() {
        this.btnRegistroBeneficiarioInstitucionLoading = true;
        this.datosInstitucionBeneficiaria.proyectosActoresLocalesId = this.id;
        await this.$store
          .dispatch(
            REGISTRAR_BENEFICIARIO_INSTITUCION,
            this.datosInstitucionBeneficiaria
          )
          .then(res => {
            this.btnLoading = false;
            //this.dialogLoaderVisible = false;
            if (res.status === 200) {
              this.$refs.snackalert.SnackbarShow(
                "success",
                "Mensaje",
                res.message
              );
              this.obtenerBeneficiariosInstitucion(this.id);
  
              this.resetFormInstitucion();
            } else {
              this.$refs.snackalert.SnackbarShow(
                "warning",
                "Alerta",
                res.message
              );
            }
            this.btnRegistroBeneficiarioInstitucionLoading = false;
          })
          .catch(() => {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              `Ha ocurrido un error durante el registro.`
            );
          });
        this.btnRegistroBeneficiarioInstitucionLoading = false;
      },
  
      //Colocar los datos en los campos para editar
      obtenerDatosBeneficiario(datoSeleccionado) {
        console.log(datoSeleccionado)
        this.accionBeneficiarios = 2;
        this.datosBeneficiario.id = datoSeleccionado.id;
        this.datosBeneficiario.departamentosId = datoSeleccionado.departamentosId;
        this.obtenerMunicipiosPorDepartamento(
          this.datosBeneficiario.departamentosId
        ).then(() => {
          if (datoSeleccionado.municipiosId) {
            this.datosBeneficiario.municipiosId = datoSeleccionado.municipiosId;
          } else {
            this.datosBeneficiario.municipiosId = null;
          }
        });
  
        if (datoSeleccionado.aldeaCaserio) {
          this.datosBeneficiario.aldeaCaserio = datoSeleccionado.aldeaCaserio;
        } else {
          this.datosBeneficiario.aldeaCaserio = "";
        }

        if (datoSeleccionado.descripcionBeneficiarios != null) {
            this.datosBeneficiario.descripcionBeneficiarios = datoSeleccionado.descripcionBeneficiarios;
        } else {
            this.datosBeneficiario.descripcionBeneficiarios = "";
        }

        this.datosBeneficiario.areasBeneficiadasId =
          datoSeleccionado.areasBeneficiadasId;
        this.datosBeneficiario.etniasId = datoSeleccionado.etniasId;
        this.datosBeneficiario.rangosEdadId = datoSeleccionado.rangosEdadId;
        this.datosBeneficiario.totalBeneficiarios = datoSeleccionado.totalBeneficiarios.toString();
        datoSeleccionado.latitud != null ? this.beneficiarioLatitud = datoSeleccionado.latitud.toString() : this.beneficiarioLatitud = "";
        datoSeleccionado.longitud != null ? this.beneficiarioLongitud = datoSeleccionado.longitud.toString() : this.beneficiarioLongitud = "";
  
        this.generoSeleccionado = this.generos.find(
          dato => dato.codigo == datoSeleccionado.genero
        );
      },
  
      //Eliminar registro de beneficiario directo
      async eliminarBeneficiarioDirecto(id) {
        this.btnEliminarBeneficiarioDisabled = true;
        await this.$store
          .dispatch(ELIMINAR_BENEFICIARIO_ACL, id)
          .then(res => {
            //this.dialogLoaderVisible = false;
            if (res.status === 200) {
              this.$refs.snackalert.SnackbarShow(
                "success",
                "Mensaje",
                res.message
              );
              this.obtenerBeneficiarios(this.id);
              //this.totalDistribucionMontos = this.distribucionesMontoSuscrito.reduce((a,b) => a + (b['montoTotalQuetzales'] || 0), 0);
              // this.resetItems();
            } else {
              this.$refs.snackalert.SnackbarShow(
                "warning",
                "Alerta",
                res.message
              );
            }
            this.btnEliminarBeneficiarioDisabled = false;
          })
          .catch(error => {
            this.btnEliminarBeneficiarioDisabled = false;
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              `Ha ocurrido un error durante el registro. ${error}`
            );
          });
      },
  
      //Eliminar registro de institucion beneficiaria
      async eliminarInstitucionBeneficiaria(id) {
        this.btnEliminarInstitucionDisabled = true;
  
        await this.$store
          .dispatch(ELIMINAR_INSTITUCION_BENEFICIARIA_CNS, id)
          .then(res => {
            //this.dialogLoaderVisible = false;
            if (res.status === 200) {
              this.$refs.snackalert.SnackbarShow(
                "success",
                "Mensaje",
                res.message
              );
              this.obtenerBeneficiariosInstitucion(this.id);
              //this.totalDistribucionMontos = this.distribucionesMontoSuscrito.reduce((a,b) => a + (b['montoTotalQuetzales'] || 0), 0);
              // this.resetItems();
            } else {
              this.$refs.snackalert.SnackbarShow(
                "warning",
                "Alerta",
                res.message
              );
            }
            this.btnEliminarInstitucionDisabled = false;
          })
          .catch(error => {
            this.btnEliminarInstitucionDisabled = false;
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              `Ha ocurrido un error durante el registro. ${error}`
            );
          });
      }
    },
  
    created() {
      this.resetItems(2, this.id);
    },
  
    computed: {
      headersBeneficiarios() {
        return [
          {
            text: "Departamento",
            align: "start",
            value: "departamento"
          },
          {
            text: "Municipio",
            align: "start",
            value: "nombreMunicipio"
          },
          {
            text: "Aldea/Caserío/Otro",
            align: "start",
            value: "nombreMunicipio"
          },
          {
            text: "Tipo de Beneficiarios",
            align: "start",
            value: "descripcionBeneficiarios"
          },
          {
            text: "Género beneficiado",
            align: "start",
            sortable: false,
            value: "nombreGenero"
          },
          {
            text: "Área",
            align: "start",
            sortable: true,
            value: "nombreArea"
          },
          {
            text: "Etnia",
            align: "start",
            sortable: true,
            value: "nombreEtnia"
          },
          {
            text: "Grupo etario",
            align: "start",
            sortable: true,
            value: "rango"
          },
          {
            text: "Beneficiados",
            align: "start",
            sortable: true,
            value: "numeroBeneficiados"
          },
          {
            text: "Acción",
            align: "start",
            sortable: true,
            value: ""
          }
        ];
      },
  
      headersInstitucionesBeneficiarias() {
        return [
          {
            text: "Insittución",
            align: "start",
            sortable: false,
            value: "nombreInstitucion"
          },
          {
            text: "Rol",
            align: "start",
            sortable: true,
            value: "nombreTipoIngreso"
          },
          {
            text: "Acción",
            align: "start",
            sortable: true,
            value: ""
          }
        ];
      }
    }
  };
  </script>
  