<template>

<div>

    <v-row>
        <v-col cols="12" md="12" sm="12" class="pt-0 pb-3">
            <v-subheader class="text-h5 black--text">
                Vinculación estratégica
            </v-subheader>
        </v-col>
    </v-row>


    <v-card class="mt-2" outlined>
        <v-card-text>

            <v-form 
                ref="formParteCooperacion"
                v-on:submit.prevent="actualizarParteCooperacion"
                v-model="validFormParteCooperacion"
            >

                <v-row class="mt-4">
                    <!--inicio:: pnd -->
                    <v-col cols="12" md="8" sm="8" xs="12" class="pt-0 pb-0">
                        <v-select
                            v-model="parteMarcoCooperacion"
                            :items="parteMarcoCooperacionItems"
                            :loading="ddPndLoading"
                            dense
                            :disabled="tipo===1"
                            class="required"
                            filled
                            label="El proyecto es parte del marco de cooperación/estrategia/lineamiento"
                            item-text="text"
                            item-value="id"
                            return-object
                            :no-data-text="
                                parteMarcoCooperacionItems != null
                                ? 'Seleccione si el proyecto es parte de un programa conjunto'
                                : 'No se han encontrado PND'
                            "
                            menu-props="auto"
                            :rules="[
                                selectRequired('parte de un programa conjunto')
                            ]"
                            >
                        </v-select>
                    </v-col>
               
                    <v-col cols="12" md="4" sm="4" xs="12" class="pt-0 pb-0" v-if="tipo===2">
                                        
                        <v-btn
                            color="light-blue-502"
                            class="white--text mb-2 float-right mt-2"
                            type="submit"
                            :elevation="0"
                            :disabled="!validFormParteCooperacion"
                            :loading="btnRegistroParteLoading"
                        >
                            Actualizar
                        </v-btn>
                    </v-col>
                </v-row>


            </v-form>

            <v-divider></v-divider>


            <v-row v-if="habilitarEstrategias">
                <v-col cols="12" md="6" sm="12" class="pt-0 pb-3">
                    <v-subheader class="text-h6 black--text">
                        Vinculación de la estrategia
                    </v-subheader>
                </v-col>

                <v-col cols="12" md="6" sm="12" class="pt-0 pb-0" v-if="tipo===2">
                                    
                    <v-btn
                        color="light-blue-502"
                        class="white--text mb-2 float-right mt-2"
                        type="submit"
                        small
                        :elevation="0"
                        :loading="btnVinculacionEstrategiaLoading"
                        @click="mostrarModalEstrategia"
                    >
                        <v-icon> mdi-plus </v-icon>
                        Registrar nueva estrategia
                    </v-btn>
                </v-col>
            </v-row>

            <v-form  v-if="habilitarEstrategias && tipo===2"
                ref="formParteCooperacion"
                v-on:submit.prevent="vincularEstrategia"
                v-model="validFormEstrategiaVinculada"
                >

                <v-row class="mt-4">
                    <!--inicio:: campo -->
                    <v-col cols="12" md="8" sm="6" xs="12" class="pt-0 pb-0">
                        <v-select
                            v-model="estrategiaCooperanteId"
                            :items="estrategiasCooperante"
                            :loading="ddEstrategiasLoading"
                            dense
                            class="required"
                            filled
                            label="Seleccione la estrategia"
                            item-text="estrategia"
                            item-value="id"
                            :no-data-text="
                                estrategiasCooperante != null
                                ? 'Seleccione la estrategia que se vincula al proyecto'
                                : 'No se han encontrado estrategias, registre una nueva'
                            "
                            menu-props="auto"
                            :rules="[
                                selectRequired('estrategia')
                            ]"
                            >
                        </v-select>
                    </v-col>
                    <!--fin:: campo -->
              
                    <v-col cols="12" md="4" sm="6" xs="12" class="pt-0 pb-0">
                                        
                        <v-btn
                            color="light-blue-502"
                            class="white--text mb-2 float-right mt-2"
                            type="submit"
                            :elevation="0"
                            :disabled="!validFormEstrategiaVinculada"
                            :loading="btnVinculacionEstrategiaLoading"
                        >
                            Vincular estrategia
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
            
            <v-row v-if="habilitarEstrategias">

                <v-col cols="12" md="12" sm="12" class="pt-0 pb-1 mt-6">
                    <v-subheader class="text-subtitle-1 black--text">
                       {{estrategiaVinculada ? `Estrategia vinculada ` : `Estrategia no vinculada `}}  <v-icon class="ml-1" v-if="estrategiaVinculada" color="teal" small>mdi-check-all</v-icon> <v-icon class="ml-1" color="red lighten-2" v-if="!estrategiaVinculada" small>mdi-warning</v-icon>
                    </v-subheader>
                </v-col>

                <v-col cols="12" md="12" sm="12" class="pt-0 pb-0" v-if="estrategiaVinculada">
                   <v-alert
                    text
                    dense
                    color="teal"
                    border="left"
                    >
                        {{nombreEstrategiaVinculada + ` | ` + fechaInicioEstrategia + ` - ` + fechaFinEstrategia }}
                    </v-alert>
                </v-col>

                <v-col cols="12" md="12" sm="12" class="pt-0 pb-0" v-if="!estrategiaVinculada">
                   <v-alert
                    text
                    dense
                    type="error"
                    border="left"
                    >
                        Debe de vincular una estrategia al proyecto
                    </v-alert>
                </v-col>


            </v-row>

        </v-card-text>
    </v-card>

    <!-- inicio:: datos de cobertura -->
    <v-card class="mt-4" outlined>
        <v-card-text>

            <v-row>
                <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                    <v-subheader class="text-h6 black--text">
                        Vinculación estratégica
                    </v-subheader>
                </v-col>
            </v-row>

             <v-form  v-if="tipo===2"
                        ref="formCobertura"
                        v-on:submit.prevent="registrarCobertura"
                        v-model="validForm"
                        >

                     <!--<v-row class="mt-4">

                        <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                            <v-select
                                v-model="datosCobertura.departamentosId"
                                :items="departamentos"
                                :loading="departamentosLoading"
                                dense
                                class="required"
                                filled
                                label="Departamento"
                                item-text="departamento"
                                item-value="id"
                                @change="obtenerMunicipiosPorDepartamento(datosCobertura.departamentosId, 1)"
                                :no-data-text="
                                    departamentos != null
                                    ? 'Seleccione un departamento'
                                    : 'No se han encontradodepartamentos'
                                "
                                menu-props="auto"
                                :rules="[
                                    selectRequired('departamento')
                                ]"
                                >
                            </v-select>
                        </v-col>

                        <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                            <v-select
                                v-model="datosCobertura.municipiosId"
                                :items="municipios"
                                :loading="municipiosLoading"
                                dense
                                class=""
                                filled
                                label="Municipio"
                                item-text="municipio"
                                item-value="id"
                                :no-data-text="
                                    municipios != null
                                    ? 'Seleccione un municipio'
                                    : 'No se han encontrado municipios'
                                "
                                menu-props="auto"
                                :rules="[
                                    selectRequired('municipio')
                                ]"
                                >
                            </v-select>
                        </v-col>

                    </v-row> -->


                     <v-row class="mt-4">
                        <!--inicio:: katun -->
                        <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                            <v-select
                                v-model="datosCobertura.ejesKatunId"
                                :items="ejesKatun"
                                :loading="ddEjesKatunLoading"
                                dense
                                class=""
                                :clearable="true"
                                filled
                                label="Eje K´atun"
                                item-text="nombreEje"
                                item-value="id"
                                @change="obtenerPrioridadesKatun(datosCobertura.ejesKatunId)"
                                :no-data-text="
                                    ejesKatun != null
                                    ? 'Seleccione Eje K´atun'
                                    : 'No se han encontrado Ejes K´atun'
                                "
                                menu-props="auto"
                                
                                >
                            </v-select>
                        </v-col>

                        <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                            <v-select
                                v-model="datosCobertura.prioridadesKatunId"
                                :items="prioridadesKatun"
                                :loading="ddPrioridadesKatunLoading"
                                dense
                                class=""
                                :clearable="true"
                                filled
                                label="Prioridad K´atun"
                                item-text="prioridad"
                                item-value="id"
                                @change="obtenerMetasKatun(datosCobertura.prioridadesKatunId)"
                                :no-data-text="
                                    prioridadesKatun != null
                                    ? 'Seleccione una prioridad K´atun'
                                    : 'No se han encontrado prioridades K´atun'
                                "
                                menu-props="auto"
                               
                                >
                            </v-select>
                        </v-col>

                        <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                            <v-select
                                v-model="datosCobertura.metasKatunId"
                                :items="metasKatun"
                                :loading="ddMetasKatunLoading"
                                dense
                                class=""
                                :clearable="true"
                                filled
                                label="Meta K´atun"
                                item-text="meta"
                                item-value="id"
                                :no-data-text="
                                    metasKatun != null
                                    ? 'Seleccione una meta K´atun'
                                    : 'No se han encontrado metas K´atun'
                                "
                                menu-props="auto"
                                
                                >
                            </v-select>
                        </v-col>

                        <!--fin :: katun -->
                    </v-row>


            

                    <v-row>
                        <!--inicio:: pnd -->
                        <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                            <v-select
                                v-model="datosCobertura.pndId"
                                :items="pnds"
                                :loading="ddPndLoading"
                                dense
                                class="required"
                                filled
                                label="Prioridad Nacional de Desarrollo"
                                item-text="nombrePND"
                                item-value="id"
                                @change="obtenerMetasPnd(datosCobertura.pndId)"
                                :no-data-text="
                                    pnds.length > 0
                                    ? 'Seleccione Prioridad Nacional de Desarrollo'
                                    : 'No se han encontrado prioridades nacionales de desarrollo'
                                "
                                menu-props="auto"
                                :rules="[
                                    selectRequired('prioridad Nacional de Desarrollo')
                                ]"
                                >
                            </v-select>
                        </v-col>

                        <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                            <v-select
                                v-model="datosCobertura.metaPNDId"
                                :items="metasPnd"
                                :loading="ddMetasPndLoading"
                                dense
                                class="required"
                                filled
                                label="Meta Prioridad Nacional de Desarrollo"
                                item-text="meta"
                                item-value="id"
                                :no-data-text="
                                    metasPnd.length > 0
                                    ? 'Seleccione una meta Prioridad Nacional de Desarrollo'
                                    : 'No se han encontrado Prioridad Nacional de Desarrollo'
                                "
                                menu-props="auto"
                                :rules="[
                                    selectRequired('meta Prioridad Nacional de Desarrollo')
                                ]"
                                >
                            </v-select>
                        </v-col>

                        <!--fin :: pnd -->
                    </v-row>


                    <v-row>
                        <!--inicio:: pgg -->
                        <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                            <v-select
                                v-model="datosCobertura.pggId"
                                :items="pggs"
                                :loading="ddPggLoading"
                                dense
                                class=""
                                :clearable="true"
                                filled
                                label="Política general de gobierno"
                                item-text="nombrePGG"
                                item-value="id"
                                @change="obtenerPilaresPgg(datosCobertura.pggId)"
                                :no-data-text="
                                    pggs.length > 0
                                    ? 'Seleccione política general de gobierno'
                                    : 'No se han encontrado políticas generales de gobierno'
                                "
                                menu-props="auto"
                                
                                >
                            </v-select>
                        </v-col>

                        <!--inicio:: pgg -->
                        <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                            <v-select
                                v-model="datosCobertura.pilarPGGId"
                                :items="pilaresPgg"
                                :clearable="true"
                                :loading="ddPilaresPggLoading"
                                dense
                                class=""
                                filled
                                label="Pilar política general de gobierno"
                                item-text="pilar"
                                item-value="id"
                                :no-data-text="
                                    pilaresPgg != null
                                    ? 'Seleccione un pilar política general de gobierno'
                                    : 'No se han encontrado pilares política general de gobierno'
                                "
                                menu-props="auto"
                               
                                >
                            </v-select>
                        </v-col>


                        <!--inicio:: metas estrategucas pgg 
                        <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                            <v-select
                                v-model="datosCobertura.metasEstrategicasPGGId"
                                :items="metasEstrategicasPgg"
                                :loading="ddMetasEstrategicasPggLoading"
                                dense
                                class="required"
                                filled
                                label="Meta estretégica PGG"
                                item-text="meta"
                                item-value="id"
                                @change="obtenerObjetivosEstrategicosPgg(datosCobertura.metasEstrategicasPGGId)"
                                :no-data-text="
                                    metasEstrategicasPgg != null
                                    ? 'Seleccione una meta estraégica pgg'
                                    : 'No se han encontrado metas estratégicas pgg'
                                "
                                menu-props="auto"
                                :rules="[
                                    selectRequired('Meta estrategica pgg')
                                ]"
                                >
                            </v-select>
                        </v-col>

                        inicio:: objetivos estrategucos pgg 
                        <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                            <v-select
                                v-model="datosCobertura.objetivosEstrategicosPGGId"
                                :items="objetivosEstrategicosPgg"
                                :loading="ddObjetivosEstrategicosPggLoading"
                                dense
                                filled
                                label="Objetivo estretégico PGG"
                                item-text="objetivo"
                                item-value="id"
                                @change="obtenerObjetivosSectorialessPgg(datosCobertura.objetivosEstrategicosPGGId)"
                                :no-data-text="
                                    objetivosEstrategicosPgg != null
                                    ? 'Seleccione un objetivo estratégico pgg'
                                    : 'No se han encontrado objetivos estratégicos pgg'
                                "
                                menu-props="auto"
                                >
                            </v-select>
                        </v-col>


                        inicio:: objetivos sectoriales pgg 
                        <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                            <v-select
                                v-model="datosCobertura.objetivosSectorialesPGGId"
                                :items="objetivosSectorialesPgg"
                                :loading="ddObjetivosSectorialesPggLoading"
                                dense
                                filled
                                label="Objetivo sectorial pgg"
                                item-text="objetivo"
                                item-value="id"
                                :no-data-text="
                                    objetivosSectorialesPgg != null
                                    ? 'Seleccione un objetivo sectorial pgg'
                                    : 'No se han encontrado objetivos sectoriales pgg'
                                "
                                menu-props="auto"
                                >
                            </v-select>
                        </v-col>-->

                    </v-row>
                    <!--fin: pgg -->

                    <v-row class="mt-4">
                        <v-col cols="12" md="12" sm="12">
                            <v-subheader class="text-h6  black--text">
                                Vinculación estratégica internacional 
                            </v-subheader>
                            <v-divider class="mt-0 pt-0"></v-divider>
                        </v-col>
                    </v-row>


                    <v-row>
                        <!--inicio:: ods -->
                        <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                            <v-select
                                v-model="datosCobertura.odsId"
                                :items="ods"
                                :loading="ddOdsLoading"
                                dense
                                class="required"
                                filled
                                label="Objetivo de Desarrollo Sostenible"
                                item-text="nombreODS"
                                item-value="id"
                                @change="obtenerMetasOds(datosCobertura.odsId)"
                                :no-data-text="
                                    pnds.length > 0
                                    ? 'Seleccione Objetivo de Desarrollo Sostenible'
                                    : 'No se han encontrado Objetivos de Desarrollo Sostenible'
                                "
                                menu-props="auto"
                                :rules="[
                                    selectRequired('Objetivo de Desarrollo Sostenible principal')
                                ]"
                                >
                            </v-select>
                        </v-col>

                        <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                            <v-select
                                v-model="datosCobertura.metasODSId"
                                :items="metasOds"
                                :loading="ddMetasOdsLoading"
                                dense
                                class="required"
                                filled
                                label="Meta Objetivo de Desarrollo Sostenible"
                                item-text="meta"
                                item-value="id"
                                :no-data-text="
                                    metasOds.length > 0
                                    ? 'Seleccione una meta Objetivo de Desarrollo Sostenible'
                                    : 'No se han encontrado metas Objetivo de Desarrollo Sostenible'
                                "
                                menu-props="auto"
                                :rules="[
                                    selectRequired('meta Objetivo de Desarrollo Sostenible')
                                ]"
                                >
                            </v-select>
                        </v-col>

                        <!--fin :: ods -->

                        <!-- inicio:: ods secundario-->
                        <!-- <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                            <v-select
                                v-model="datosCobertura.odsSecundarioId"
                                :items="ods"
                                :loading="ddOdsLoading"
                                dense
                                filled
                                label="Objetivo de Desarrollo Sostenible Secundario"
                                item-text="nombreODS"
                                item-value="id"
                                :no-data-text="
                                    pnds.length > 0
                                    ? 'Seleccione Objetivo de Desarrollo Sostenible secundario'
                                    : 'No se han encontrado Objetivos de Desarrollo Sostenible'
                                "
                                menu-props="auto"
                                >
                            </v-select>
                        </v-col> -->

                        <!--fin :: ods -->
                    </v-row>


                   


                    


                    <!--inicio:: monto 
                    <v-row>
                        
                        <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                            <v-text-field
                                v-model="datosCobertura.montoSuscrito"
                                dense
                                rows="3"
                                class="required"
                                filled
                                label="Monto suscrito por departamento / municipio"
                                menu-props="auto"
                                :rules="[
                                    required('monto suscrito'),
                                    minLength('monto suscrito', 1),
                                    maxLength('monto suscrito', 15),
                                    decimals('monto suscrito')
                                ]"
                                >
                            </v-text-field>
                        </v-col>

                    </v-row>
                    fin:: monto -->

                    <v-row>
                        <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                                        
                            <v-btn
                                color="light-blue-502"
                                class="white--text mb-2 float-right mt-2"
                                type="submit"
                                :elevation="0"
                                :disabled="!validForm"
                                :loading="btnRegistroLoading"
                            >
                                {{accionVinculacion === 1 ? `Registrar vinculación` : `Actualizar vinculación`}} 
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>

                <v-row class="mt-4 d-none">
                    <v-col cols="12" md="12" sm="12">
                        <v-subheader class="text-h6  black--text">
                            Vinculación registrada 
                        </v-subheader>
                        <v-divider class="mt-0 pt-0"></v-divider>
                    </v-col>
                </v-row>

                <v-row class="d-none">
                    <!--inicio:: tabla de documentos cargados :headers="headersCoberturas" -->
                    <v-col cols="12" md="12" sm="12" class="pt-4">
                        <v-data-table
                            class="elevation-0"
                            
                            :items="coberturas"
                            :loading="tableCoberturaLoading"
                            hide-default-footer
                            :footer-props="{
                                showFirstLastPage: true,
                                firstIcon: 'mdi-page-first',
                                lastIcon: 'mdi-page-last',
                                prevIcon: 'mdi-chevron-left',
                                nextIcon: 'mdi-chevron-right',
                                'items-per-page-text': 'Registros por página',
                                pageText: '{0}-{1} de {2}'
                            }"
                            >

                                <template v-slot:item="{ item }">
                                    <tr>
                                        <!-- <td>{{ item.municipio ? item.municipio + ", " :  ""}} {{item.departamento}}</td> -->
                                        <td>
                                            <p class="text-subtitle-1 mb-0 font-weight-bold">Prioridad nacional de desarrollo</p>
                                            <p class="text-subtitle-1">{{item.nombrePND}}</p>
                                            <p class="text-subtitle-1 mb-0 font-weight-bold">Meta PND</p> 
                                            <p class="text-subtitle-1">{{ item.metaPND }}</p>
                                        </td>
                                        <td>
                                            <p class="text-subtitle-1 mb-0 font-weight-bold">Objetivo de desarrollo sostenible</p>
                                            <p class="text-subtitle-1">{{item.nombreODS}}</p>
                                            <p class="text-subtitle-1 mb-0 font-weight-bold">Meta ODS</p>
                                            <p class="text-subtitle-1">{{ item.metaODS }}</p>
                                            <p class="text-subtitle-1 mb-0 font-weight-bold">ODS secundario</p>
                                            <p class="text-subtitle-1">{{ item.nombreODSSecundario}}</p>
                                        </td>
                                        <td>
                                            <p class="text-subtitle-1 mb-0 font-weight-bold">Eje K'atun</p>
                                            <p class="text-subtitle-1">{{item.nombreEje}}</p>
                                            <p class="text-subtitle-1 mb-0 font-weight-bold">Prioridad K'atun</p>
                                            <p class="text-subtitle-1">{{item.prioridad}} </p>
                                            <p class="text-subtitle-1 mb-0 font-weight-bold">Meta K'atun</p>
                                            <p class="text-subtitle-1">{{ item.metaKatun }}</p>
                                        </td>
                                        <td>
                                            <p class="text-subtitle-1 mb-0 font-weight-bold">Política general de gobierno</p> 
                                            <p class="text-subtitle-1">{{item.nombrePGG}}</p>
                                            <p class="text-subtitle-1 mb-0 font-weight-bold">Pilar PGG</p> 
                                            <p class="text-subtitle-1">{{ item.pilar }}</p>
                                        </td>
                                        <!--<td>
                                            <v-chip
                                                class="ma-2 font-weight-medium"
                                                label
                                                :color="
                                                    item.estadosId === 1
                                                    ? 'blue lighten-4'
                                                    : item.estadosId === 2
                                                    ? 'deep-orange lighten-4'
                                                    : item.estadosId === 6
                                                    ? 'cyan lighten-4'
                                                    : item.estadosId === 7
                                                    ? 'deep-orange lighten-4'
                                                    : 'pink lighten-4'
                                                "
                                                :text-color="
                                                    item.estadosId === 1
                                                    ? 'blue lighten-1'
                                                    : item.estadosId === 2
                                                    ? 'deep-orange lighten-1'
                                                    : item.estadosId === 6
                                                    ? 'cyan darken-1'
                                                    : item.estadosId === 7
                                                    ? 'deep-orange lighten-1'
                                                    : 'pink darken-1'
                                                "
                                                small
                                                >
                                                {{ item.estado }}
                                            </v-chip>
                                        </td>-->
                                        <!-- <td> {{parseFloat(item.montoSuscrito).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ' })}}</td> -->
                                       <!--  <td>
                                            <v-btn v-if="item.estadosId==1"
                                                class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                small
                                                depressed
                                                :disabled="btnEliminarCoberturaDisabled"
                                                :loading="btnEliminarCoberturaDisabled"
                                                color="blue-grey lighten-5"
                                                @click="eliminarItem(item.id)"
                                                >
                                                <v-icon left>mdi-delete</v-icon> Eliminar vinculación
                                            </v-btn>

                                            <v-btn v-if="item.estadosId==1"
                                                class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                small
                                                depressed
                                                :disabled="false"
                                                :loading="btnObtenerCoberturaLoading"
                                                color="blue-grey lighten-5"
                                                @click="editarCobertura(item.id)"
                                                >
                                                <v-icon left>mdi-edit</v-icon> Editar vinculación
                                            </v-btn>
                                        </td> -->
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-col>
                  </v-row>
            </v-card-text>
        </v-card>
        <!-- fin:: datos de cobertura -->


    <!-- inicio:: CAD CRS ETC-->
    <v-card class="mt-6" outlined v-if="0>1">
        <v-card-text>

            <v-row>
                <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                    <v-subheader class="text-h6 black--text">
                        Sector que beneficia
                    </v-subheader>
                </v-col>

            </v-row>

            <v-row class="mt-4">
               <!--  <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                    <v-select
                    v-model="cadSeleccionado"
                    :items="cads"
                    :loading="cadsLoading"
                    dense
                    filled
                    label="CAD"
                    item-text="nombreCAD"
                    item-value="id"
                    return-object
                    @change="obtenerCrsPorCadSeleccionado"
                    :no-data-text="
                        cads != null
                        ? 'Seleccione un CAD'
                        : 'No se han encontrado CADS'
                    "
                    menu-props="auto"
                    ></v-select>
                </v-col>

                <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                    <v-select
                    v-model="crsSeleccionado"
                    :items="crs"
                    :loading="crsLoading"
                    dense
                    filled
                    label="CRS"
                    item-text="nombreCRS"
                    item-value="id"
                    return-object
                    :no-data-text="
                        crs != null
                        ? 'Seleccione un CRS'
                        : 'No se han encontrado CRS'
                    "
                    menu-props="auto"
                    ></v-select>
                </v-col> -->

                 <!-- <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                    <v-select
                    v-model="politicaPublicaSeleccionada"
                    :items="politicasPublicas"
                    :loading="politicaPublicaLoading"
                    dense
                    filled
                    label="Política pública"
                    item-text="nombreSectorBeneficiado"
                    item-value="id"
                    return-object
                    :no-data-text="
                        politicasPublicas != null
                        ? 'Seleccione un polítca pública'
                        : 'No se han encontrado polítcas públicas'
                    "
                    menu-props="auto"
                    ></v-select>
                </v-col> -->
            </v-row>

            <v-row>
                <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                    <v-select
                    v-model="aportaPddSeleccionado"
                    :items="aportaPddItems"
                    :loading="false"
                    dense
                    filled
                    label="El proyecto aporta al Plan de Desarrollo Departamental (PDD)"
                    item-text="text"
                    item-value="id"
                    return-object
                    menu-props="auto"
                    ></v-select>
                </v-col>
                
                <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                    <v-select
                    v-model="aportaPdmSeleccionado"
                    :items="aportaPdmItems"
                    :loading="false"
                    dense
                    filled
                    label="El proyecto aporta a Planes de Desarrollo Municipal y Ordenamiento Territorial (PDM-OT)"
                    item-text="text"
                    item-value="id"
                    return-object
                    menu-props="auto"
                    ></v-select>
                </v-col>

                <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                    <v-select
                    v-model="vinculaPeiSeleccionado"
                    :items="vinculaPeiItems"
                    :loading="false"
                    dense
                    filled
                    label="El proyecto se vincula al Plan Estratégico Institucional (PEI)"
                    item-text="text"
                    item-value="id"
                    return-object
                    menu-props="auto"
                    ></v-select>
                </v-col>


                <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                    <v-select
                    v-model="vinculaPoaSeleccionado"
                    :items="vinculaPoaItems"
                    :loading="false"
                    dense
                    filled
                    label="El proyecto se vincula al Plan Operativo Anual (POA)"
                    item-text="text"
                    item-value="id"
                    return-object
                    menu-props="auto"
                    ></v-select>
                </v-col>
            </v-row>

             <v-row>
                 <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                                
                    <v-btn
                        color="light-blue-502"
                        class="white--text mb-2 float-right mt-2"
                        type="submit"
                        :elevation="0"
                        :disabled="!validForm"
                        :loading="btnRegistroLoading"
                    >
                        Guardar información
                    </v-btn>
                </v-col>
            </v-row>

        </v-card-text>
    </v-card>


    <!-- fin:: CAD CRS ETC-->

    <AsigOdsSecundarioComponent ref="AsigOdsSecundarioComponent" :proyectoId="id" :tipoProyecto="2" :tipoAccion="tipo"/>


    <!--inicio::  clasificación OCDE  -->
    <v-card class="mt-6" outlined>
        <v-card-text>
            <v-row>
                <v-col cols="12" md="12" sm="12" class="pt-6 pb-0">
                    <v-subheader class="text-h6 black--text">
                        Vinculación del proyecto al sector de la Organización para la Cooperación y el Desarrollo Económicos (OCDE) 
                    </v-subheader>
                </v-col>
            </v-row>

             <v-form
                ref="form"
                v-on:submit.prevent="actualizarClasificacionOCDE"
                v-model="validFormClasificacion"
                class="pt-4 pl-2 pr-2"
                >

                <v-row class="mt-4">
                    <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                        <v-select
                            @change="obtenerCrsPorCadSeleccionado"
                            class=""
                            v-model="cadSeleccionado"
                            :items="cads"
                            :loading="cadsLoading"
                            dense
                            filled
                            label="Comité de Ayuda al Desarrollo (CAD)"
                            item-text="nombreCAD"
                            item-value="id"
                            return-object
                            :no-data-text="
                                cads.length > 0
                                ? 'Seleccione un CAD'
                                : 'No se han encontrado CADS'
                            "
                            menu-props="auto"
                        ></v-select>
                    </v-col>

                    <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                        <v-select
                        v-model="crsSeleccionado"
                        class=""
                        :items="crs"
                        :loading="crsLoading"
                        dense
                        filled
                        label="Estándar común de informes (CRS)"
                        item-text="nombreCRS"
                        item-value="id"
                        return-object
                        :no-data-text="
                            crs.length > 0
                            ? 'Seleccione un estándar común de informes (CRS)'
                            : 'No se han encontrado estándares común de informes (CRS)'
                        "
                        menu-props="auto"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                                    
                        <v-btn
                            color="light-blue-502"
                            class="white--text mb-2 float-right mt-2"
                            type="submit"
                            :elevation="0"
                            :disabled="!validFormClasificacion"
                            :loading="btnRegistroClasificacionLoading"
                        >
                            Actualizar clasificación
                        </v-btn>
                    </v-col>
                </v-row>
             </v-form>
        </v-card-text>
    </v-card>

    

    <!--inicio::  clasificación OCDE  -->
    <v-card class="mt-6 d-none" outlined>
        <v-card-text>
            <v-row>
                <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
                    <v-subheader class="text-h6 black--text">
                        Política pública
                    </v-subheader>
                </v-col>
            </v-row>

             <v-form
                ref="form"
                v-on:submit.prevent="actualizarPoliticaPublica"
                v-model="validFormPoliticaPublica"
                class="pt-4 pl-2 pr-2"
                >

                <v-row class="mt-4">
                    <v-col cols="12" md="8" sm="8" xs="12" class="pt-0 pb-0">
                        <v-select
                        class="required"
                        v-model="politicaPublicaSeleccionada"
                        :items="politicasPublicas"
                        :loading="politicaPublicaLoading"
                        dense
                        filled
                        label="Política pública"
                        item-text="politica"
                        item-value="id"
                        return-object
                        :rules="[
                            selectRequired('política pública')
                        ]"
                        :no-data-text="
                            politicasPublicas.length > 0
                            ? 'Seleccione un polítca pública'
                            : 'No se han encontrado polítcas públicas'
                        "
                        menu-props="auto"
                        ></v-select>
                    </v-col>

                    <v-col cols="12" md="4" sm="4" xs="12" class="pt-0 pb-0">
                                    
                        <v-btn
                            color="light-blue-502"
                            class="white--text mb-2 float-right mt-2"
                            type="submit"
                            :elevation="0"
                            :disabled="!validFormPoliticaPublica"
                            :loading="btnRegistroPoliticaLoading"
                        >
                            Actualizar política pública
                        </v-btn>
                    </v-col>
                </v-row>
                
             </v-form>
        </v-card-text>
    </v-card>

    <!--begin:: modal registro-->
    <v-dialog
      v-model="dialogRegistroEstrategia"
      max-width="1024"
      transition="scroll-y-transition"
      persistent
    >
      <v-card tile>
        <v-card-title>
          Registrar estrategia

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="
              dialogRegistroEstrategia = false;
              resetFormEstrategia();
            "
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="d-none"></v-divider>

        <v-card-text>

            <v-form
                ref="form"
                v-on:submit.prevent="registrarEstrategia"
                v-model="validFormEstrategia"
                class="pt-4 pl-2 pr-2"
                >

                <v-row class="mt-4">
                  <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                        <v-select
                        v-model="tipoFuenteCooperanteSeleccionada"
                            :items="tiposFuenteCooperante"
                            :loading="false"
                            dense
                            filled
                            class="required"
                            label="Tipo fuente cooperante"
                            item-text="nombreTipoFuente"
                            item-value="id"
                            return-object
                            :rules="[selectRequired('nombre del tipo fuente cooperante')]"
                            :no-data-text="
                                tiposFuenteCooperante != null
                                ? 'Selecciona un tipo de fuente cooperante'
                                : 'No se han encontrado tipos de fuente cooperante'
                            "
                            menu-props="auto"
                        ></v-select>
                    </v-col>

                    <v-col cols="12" md="4" sm="6" class="pt-0 pb-0" v-if="tipoFuenteCooperanteSeleccionada.id == 1">
                        <v-select
                          v-model="fuenteBilateralSeleccionada"
                          :items="fuentesBilaterales"
                          :loading="false"
                          :required="tipoFuenteCooperanteSeleccionada.id == 1"
                          dense
                          :class="tipoFuenteCooperanteSeleccionada.id == 1 ? 'required' : ''"
                          filled
                          label="País fuente bilateral"
                          item-text="pais"
                          item-value="id"
                          return-object
                          :rules="[
                              tipoFuenteCooperanteSeleccionada.id === 1 ? selectRequired('país bilateral') : true,
                          ]"
                          :no-data-text="
                              fuentesBilaterales != null
                              ? 'Selecciona un país'
                              : 'No se han encontrado países'
                          "
                          @change="obtenerAgenciasPorFuenteBilateral()"
                          menu-props="auto"
                        ></v-select>
                    </v-col>

                    <!-- inicio:: agencias -->
                    <v-col cols="12" md="4" sm="6" class="pt-0 pb-0" v-if="tipoFuenteCooperanteSeleccionada.id == 1">
                        <v-select
                          v-model="agenciaSeleccionada"
                          :items="agencias"
                          :loading="ddAgenciasLoading"
                          dense
                          filled
                          label="Seleccione agencia"
                          item-text="nombreAgencia"
                          item-value="id"
                          return-object
                          :no-data-text="
                              fuentesBilaterales != null
                              ? 'Selecciona una agencia'
                              : 'No se han encontrado agencias'
                          "
                          menu-props="auto"
                        ></v-select>
                    </v-col>
                    <!-- fin:: agencias -->

                    <v-col cols="12" md="6" sm="12" class="pt-0 pb-0" v-if="tipoFuenteCooperanteSeleccionada.id == 2">
                        <v-select
                          v-model="fuenteMultilateralSeleccionada"
                          :items="fuentesMultilateral"
                          :loading="false"
                          dense
                          filled
                          label="Seleccione fuente multilateral"
                          item-text="nombreFuenteMultilateral"
                          item-value="id"
                          return-object
                          :no-data-text="
                              fuentesMultilateral != null
                              ? 'Seleccione una fuente multilateral'
                              : 'No se han encontrado fuentes multilaterales'
                          "
                          menu-props="auto"
                        ></v-select>
                    </v-col>
                </v-row>

                <v-row>                
                    <v-col cols="12" md="12" class="pt-0 pb-0">
                        <v-textarea
                            autocomplete="off"
                            class="required"
                            dense
                            rows="2"
                            filled
                            color="blue-grey lighten-2"
                            v-model="datosEstrategia.estrategia"
                            label="Nombre de la estrategia"
                            :rules="[
                                required('nombre de la estrategia'),
                                minLength('nombre de la estrategia', 5),
                                maxLength('nombre de la estrategia', 500)
                            ]"
                            maxlength="500"
                        ></v-textarea>
                    </v-col>
                </v-row>

                <v-row>                
                    <v-col cols="12" md="12" class="pt-0 pb-0">
                        <v-textarea
                            autocomplete="off"
                            class="required"
                            dense
                            rows="2"
                            filled
                            color="blue-grey lighten-2"
                            v-model="datosEstrategia.resultados"
                            label="Resultados de la estrategia"
                            :rules="[
                                required('resultados de la estrategia'),
                                minLength('resultados de la estrategia', 5),
                                maxLength('resultados de la estrategia', 500)
                            ]"
                            maxlength="500"
                        ></v-textarea>
                    </v-col>
                </v-row>

                <v-row>                
                  <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                        <v-text-field
                            dense
                            filled
                            autocomplete="off"
                            class="required"
                            color="blue-grey lighten-2"
                            v-model="fechaInicio"
                            label="Fecha de inicio"
                            :rules="[
                                required('fecha de inicio'),
                                minLength('fecha de inicio', 10),
                                dateFormat('fecha de inicio')
                            ]"
                            maxlength="10"
                            v-mask="'##/##/####'"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                        <v-text-field
                            dense
                            filled
                            autocomplete="off"
                            class="required"
                            color="blue-grey lighten-2"
                            v-model="fechaFinalizacion"
                            label="Fecha de finalización"
                            :rules="[
                                required('fecha de finalización'),
                                minLength('fecha de finalización', 10),
                                dateFormat('fecha de finalización'),
                                dateEqualGreatherThan('fecha de finalización', fechaInicio)
                            ]"
                            maxlength="10"
                            v-mask="'##/##/####'"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                        <v-text-field
                            dense
                            filled
                            autocomplete="off"
                            class="required"
                            color="blue-grey lighten-2"
                            v-model="montoMonedaUsd"
                            label="Monto en dólares"
                            prefix="USDM"
                            :rules="[
                                required('monto en dólares'),
                                decimals10('monto en dólares')
                            ]"
                            maxlength="30"
                        ></v-text-field>
                    </v-col>


                    <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                        <v-text-field
                            dense
                            filled
                            autocomplete="off"
                            class="required"
                            color="blue-grey lighten-2"
                            v-model="tipoCambio"
                            label="Tipo de cambio"
                            :rules="[
                                required('tipo de cambio'),
                                decimals10('tipo de cambio')
                            ]"
                            maxlength="30"
                        ></v-text-field>
                    </v-col>


                    <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                        <v-text-field
                            dense
                            filled
                            :disabled="true"
                            autocomplete="off"
                            class="required"
                            color="blue-grey lighten-2"
                            v-model="datosEstrategia.montoQuetzales"
                            label="Monto total quetzales"
                            maxlength="30"
                            prefix="GTQ"
                        ></v-text-field>
                    </v-col>

                </v-row>

                              


                
                <v-row>
                    <v-col cols="12" md="12" class="pb-0">
                        <v-spacer></v-spacer>
                        <!--:disabled="!validDocForm" type="submit"-->
                        <v-btn
                            color="light-blue-502"
                            class="white--text mb-2 float-right ml-2"
                            type="submit"
                            :elevation="0"
                            :disabled="!validFormEstrategia"
                            :loading="btnRegistroEstrategiaLoading"
                        >
                            Registrar estrategia
                        </v-btn>

                        <v-btn
                            color="grey lighten-5"
                            elevation="0"
                            class="mb-2 float-right grey lighten-5"
                            @click="
                                dialogRegistroEstrategia = false;
                                resetFormEstrategia();
                            "
                            :disabled="btnRegistroEstrategiaLoading"
                        >
                            Cancelar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
    </v-dialog>


    <!--end:: modal registro-->




    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
    ></DialogLoader>
    <!---->
</div>
</template>


<script>
import DialogLoader from "@/view/content/DialogLoader";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import validations from "@/core/untils/validations.js";

//Seccion ods secundario
import AsigOdsSecundarioComponent from "@/view/pages/proyectos-cns/externo/secciones/AsigOdsSecundarioComponent.vue";

import { OBTENER_DATOS_SECCIONES_PROYECTO } from "@/core/services/store/proyectoscns/proyectocns.module";
import {OBTENER_ITEMS_CATALOGO, OBTENER_SUBITEMS_CATALOGO} from "@/core/services/store/catalogos/catalogointerno.module";

import {OBTENER_PROYECTO_CNSCOO, ACTUALIZAR_PARTE_COOPERACION_ESTRATEGIA_CNSC, VINCULAR_ESTRATEGIA_PROYECTO_CNSC, ACTUALIZAR_CLASIFICACION_OCDE_CNSC, ACTUALIZAR_POLITICA_PUBLICA_CNSC} from "@/core/services/store/proyectoscooperantes/proyectocooperantes.module";


import { OBTENER_ESTRATEGIAS_CNSC, REGISTRAR_ESTRATEGIA_CNSC} from "@/core/services/store/cooperantes/estrategias/estrategia.module";

import { OBTENER_DEPARTAMENTOS_PAIS } from "@/core/services/store/catalogos/departamentos/departamento.module";
import { OBTENER_MUNICIPIOS_DEPARTAMENTO } from "@/core/services/store/catalogos/municipios/municipio.module";

import {OBTENER_COBERTURAS_CNS, REGISTRAR_COBERTURA_CNS, ACTUALIZAR_COBERTURA_CNS,  ELIMINAR_COBERTURA_CNS } from "@/core/services/store/proyectoscns/coberturas/coberturacns.module";
import moment from "moment";
export default {
    name: 'SeccionVinculacionEstrategicaProyectoCooperacion',
    props: ['id', 'tipo'], //Tipo 1 segeplan (solo visualizar), tipo 2 externo (visualizar y editar)
    data(){
        return{
            validForm: false,
            btnRegistroLoading: false,
            dialogLoaderVisible: false,
            dialogLoaderText: '',
            ddOdsLoading:false,
            btnEliminarCoberturaDisabled: false,
            sectoresBeneficiadosLoading: false,
            sectoresBeneficiadosSeleccionado: {},
            sectoresBeneficiados: [],
            parteMarcoCooperacionItems: [{id: 1, text: "Si"}, {id: 2, text: "No"}],
            parteMarcoCooperacion: {},
            habilitarEstrategias: false,
            validFormParteCooperacion: false,
            validFormEstrategia: false,
            validFormEstrategiaVinculada: false,
            btnRegistroEstrategiaLoading: false,
            btnRegistroParteLoading: false,
            btnVinculacionEstrategiaLoading: false,
            estrategiaCooperanteId: 0,
            estrategiasCooperante: [],
            ddEstrategiasLoading: false,
            btnVincularEstrategiaLoading:false,
            tableCoberturaLoading: false,
            dialogRegistroEstrategia:false,
            tiposFuenteCooperante: [],
            fuentesBilaterales: [],
            agencias: [],
            ddAgenciasLoading: false,
            fuentesMultilateral: [],
            tipoFuenteCooperanteSeleccionada: {},
            fechaFinalizacion: "",
            fechaInicio: "",
            montoMonedaUsd: "",
            tipoCambio: "",
            datosEstrategia: {
                estrategia: "",
                resultados: "",
                fechaInicio: "",
                fechaFinalizacion: "",
                fuentesBilateralesId: 0,
                agenciasId: 0,
                montoUSD: 0,
                tipoCambio: 0,
                montoQuetzales:0,
                fuentesMultilateralesId: 0,
                tiposFuenteCooperanteId: 0,
                estadosId: 1,
                usuarioCreacion: "admin"
            },
            coberturas: [],
            departamentosLoading: false,
            departamentoSeleccionado: {},
            departamentos: [],
            municipioSeleccionado: {},
            municipios: [],
            municipiosLoading: false,
            pndSeleccionada: {},
            ddPndLoading: false,
            ods:[],
            odsSeleccionada:{},
            ddMetasOdsLoading: false,
            metasOds:[],
            pnds: [],
            metaPndSeleccionada: {},
            ddMetasPndLoading: false,
            metasPnd: [],
            ejeKatunSeleccionado: {},
            ejesKatun: [],
            ddEjesKatunLoading: false,
            ddPrioridadesKatunLoading: false,
            prioridadesKatun: [],
            prioridadKatunSeleccionada: {},
            ddMetasKatunLoading: false,
            metasKatun: [],
            pggs: [],
            pggSeleccionada:{},
            ddPggLoading: false,
            pilaresPgg: [],
            pilarPggSeleccionado: {},
            ddPilaresPggLoading: false,
            ddMetasEstrategicasPggLoading: false,
            metasEstrategicasPgg: [],
            metaEstrategicaPggSeleccionada:{},
            objetivoEstrategicoPggSeleccionado: {},
            objetivosEstrategicosPgg: [],
            ddObjetivosEstrategicosPggLoading: false,
            objetivoSectorialSeleccionado: {},
            ddObjetivosSectorialesPggLoading: false,
            objetivosSectorialesPgg: [],
            municipiosActividadLoading: false,
            //metaEstrategicaPggId: 0,
            validFormClasificacion:false,
            btnRegistroClasificacionLoading: false,
            cadSeleccionado:{},
            cadsLoading: false,
            cads:[],
            crsSeleccionado:{},
            crs: [],
            crsLoading: false,
            validFormPoliticaPublica: false,
            btnRegistroPoliticaLoading: false,
            politicaPublicaSeleccionada:{},
            politicasPublicas: [],
            politicaPublicaLoading:false,
            politicaPubilcaLoading: false,
            aportaPddSeleccionado: {},
            aportaPddItems: [{id: 1, text: 'No'}, {id:2, text: 'Si'}],
            aportaPdmSeleccionado: {},
            aportaPdmItems: [{id: 1, text: 'No'}, {id:2, text: 'Si'}],
            vinculaPeiSeleccionado: {},
            vinculaPeiItems: [{id: 1, text: 'No'}, {id:2, text: 'Si'}],
            vinculaPoaSeleccionado: {},
            vinculaPoaItems: [{id: 1, text: 'No'}, {id:2, text: 'Si'}],
            cuentaMontoSuscritoActividadSeleccionado: 0,
            cuentaMontoSuscritoActividadItems: [{id: 1, text: 'No'}, {id:2, text: 'Si'}],
            departamentosActividadLoading: false,
            departamentoActividadSeleccionado: {},
            departamentosActividad: [],
            municipioActividadSeleccionado: {},
            municipiosActividad: [],

            actividadesLoading: false,
            actividades: [],
            nombreEstrategiaVinculada: "",
            estrategiaVinculada: false,
            datosProyecto: {},
            fechaInicioEstrategia: "",
            fechaFinEstrategia: "",
            datosItem: {
                sectoresBeneficiadosId: 0,
            },
            datosActividad: {
                departamentosId: 0,
                municipíosId: null,
                actividadesId: 0,
                monto: ''
            },
            datosCobertura: {
                id: 0,
                proyectoCNSCId: this.id,
                pndId: 0,
                metaPNDId: 0,
                prioridadesKatunId: 0,
                ejesKatunId: 0,
                metasKatunId: 0,
                odsId: 0,
                metasODSId: 0,
                pggId: 0,
                pilarPGGId: 0,
                //metasEstrategicasPGGId: 0,
                odsSecundarioId: 0,
                //objetivosSectorialesPGGId: 0,
                //objetivosEstrategicosPGGId: 0,
                //montoSuscrito: "",
                estadosId: 1,
                usuarioCreacion: "admin",

            },
            
            datosPoliticaPublica:{
                proyectoCNSCId: this.id,
                politicaPublicaId: 0,
                usuarioActualizacion: "admin"
            },

            vinculacionRegistrada: false,
            accionVinculacion: 1,
            
            ...validations,


            // cadSeleccionado: {},
          
        }
    },

    components: {
        DialogLoader,
        SnackAlert,
        AsigOdsSecundarioComponent
    },

    methods: {
        
        //Restablecer los campos
        //El tipo se usa para poner la animacion de todo el card (1) o solo la tabla (2)
        resetItems(tipo){
            
            if(tipo===1){
                this.skeletonLoading=true;
            }

            this.vinculacionRegistrada=false;
            this.accionVinculacion=1;
            this.politicaPublicaSeleccionada={};
            this.coberturas = [];
            this.tableCoberturaLoading=true;
            this.pndSeleccionada={};
            this.odsSeleccionada={};
            this.ejeKatunSeleccionado={};
            this.prioridadKatunSeleccionada={};
            this.pggSeleccionada = {};
            this.pilarPggSeleccionado = {};
            this.metaEstrategicaPggSeleccionada ={};
            this.objetivoEstrategicoPggSeleccionado={};

            this.cadSeleccionado = {};
            this.crsSeleccionado = {};
            
            this.datosCobertura= {
                id: 0,
                proyectoCNSCId: this.id,
                pndId: 0,
                metaPNDId: 0,
                prioridadesKatunId: 0,
                ejesKatunId: 0,
                metasKatunId: 0,
                odsId: 0,
                metasODSId: 0,
                pggId: 0,
                pilarPGGId: 0,
                //metasEstrategicasPGGId: 0,
                odsSecundarioId: 0,
                //objetivosSectorialesPGGId: 0,
                //objetivosEstrategicosPGGId: 0,
                //montoSuscrito: "",
                estadosId: 1,
                usuarioCreacion: "admin",
                
            };


            this.datosPoliticaPublica={
                proyectoCNSCId: this.id,
                politicaPublicaId: 0,
                usuarioActualizacion: "admin"
            };
        },


        resetFormEstrategia(){
            this.agenciaSeleccionada = {};
            this.fuenteBilateralSeleccionada = {};
            this.fuenteMultilateralSeleccionada = {};
            this.fechaInicio = "";
            this.fechaFinalizacion = "";
            this.montoMonedaUsd = "";
            this.tipoCambio = "";
            this.nombreEstrategia="";
            this.datosEstrategia= {
                estrategia: "",
                resultados: "",
                fechaInicio: "",
                fechaFinalizacion: "",
                fuentesBilateralesId: 0,
                agenciasId: 0,
                montoUSD: 0,
                tipoCambio: 0,
                montoQuetzales:0,
                fuentesMultilateralesId: 0,
                tiposFuenteCooperanteId: 0,
                estadosId: 1,
                usuarioCreacion: "admin"
            };
        },


        async obtenerDatosProyecto(proyectoId){
            this.datosItem = [];

            await this.$store
            .dispatch(OBTENER_PROYECTO_CNSCOO, {id: proyectoId})
            .then(res => {
            //console.log(res)
                if (res.status === 200) {
                    this.datosProyecto = res.data;

                    
                    let parteMarco = 1;
                    if(this.datosProyecto.parteDeCooperacion===1){
                        parteMarco= 1;
                        this.habilitarEstrategias = true;
                        this.estrategiaVinculada=true;
                        this.nombreEstrategiaVinculada = this.datosProyecto.estrategia;
                        this.fechaInicioEstrategia = moment(this.datosProyecto.fechaInicioEstrategia, "YYYY-MM-DD").format("DD-MM-YYYY");
                        this.fechaFinEstrategia = moment(this.datosProyecto.fechaFinalizaEstrategia, "YYYY-MM-DD").format("DD-MM-YYYY");   
                        this.estrategiaCooperanteId = this.datosProyecto.estrategiaCNSCId;
                        
                    } else{
                       parteMarco = 2;
                       this.habilitarEstrategias = false;
                       this.estrategiaVinculada=false;
                       
                    }

                   
                    //Asignar cad y crs
                    //if(this.datosProyecto.cadId &&  this.datosProyecto.cadId != 0){
                        this.obtenerCad().then(()=>{
                           if(this.datosProyecto.cadId){
                        
                                this.cadSeleccionado = this.cads.find( item => item.id === this.datosProyecto.cadId);

                                if(this.cadSeleccionado.id!=0){
                                    this.obtenerCrsPorCadSeleccionado().then(()=>{
                                        this.crsSeleccionado = this.crs.find( item => item.id === this.datosProyecto.crsId);
                                    });
                                }
                            } 
                        })
                    //}


                    //Asignar la politica publica
                    this.obtenerPoliticasPublicas().then(()=>{
                        if(this.datosProyecto.politicaPublicaId){
                            this.politicaPublicaSeleccionada = this.politicasPublicas.find(item => item.id === this.datosProyecto.politicaPublicaId);
                        }
                    });
                    
                   

                    this.parteMarcoCooperacion = this.parteMarcoCooperacionItems.find(item => item.id === parteMarco);

                   

                    /* if(!this.datosProyecto.numeroDonaciones){
                        this.datosProyecto.numeroDonaciones = ''; 
                    } */
                    
                    /* this.fechaSuscripcion = moment(this.datosProyecto.fechaSuscripcion, "YYYY-MM-DD").format("DD-MM-YYYY");
                    this.fechaInicio = moment(this.datosProyecto.fechaInicio, "YYYY-MM-DD").format("DD-MM-YYYY");
                    this.fechaFinalizacion = moment(this.datosProyecto.fechaFinalizacion, "YYYY-MM-DD").format("DD-MM-YYYY"); */

                    /* this.tipoCooperacionSeleccionada = this.tiposCooperacion.find(item => item.id ===this.datosProyecto.tiposCooperacionId);
                    this.modalidadEjecucionSeleccionada = this.modalidadesEjecucion.find(item => item.id ===this.datosProyecto.modalidadEjecucionCNSCId);

                    !this.datosProyecto.codigoFuenteCooperante ? this.datosProyecto.codigoFuenteCooperante = '' : this.datosProyecto.codigoFuenteCooperante = this.datosItem.codigoFuenteCooperante;
                    !this.datosProyecto.codigoDelProyecto ? this.datosProyecto.codigoDelProyecto = '' : this.datosProyecto.codigoDelProyecto = this.datosProyecto.codigoDelProyecto; */
                }
                //this.ddTiposInstrumentoLoading = false;
                 //setTimeout(this.skeletonLoading=false, 700);
                 
            })
            .catch(() => {
                //console.log(error)
                this.skeletonLoading=false;
                //this.datosItem = [];
            // this.ddTiposInstrumentoLoading = false;
            });
        },


        async obtenerEstrategias(){
            
            this.ddEstrategiasLoading = true;
            this.estrategiasCooperante = [];
            await this.$store
                .dispatch(OBTENER_ESTRATEGIAS_CNSC, {estadoId: 0, fuentesBilateralesId: 0, agenciasId: 0, fuentesMultilateralesId: 0, cooperanteId: 0})
                .then(res => {
                    if (res.status === 200) {
                        this.estrategiasCooperante = res.data;
                    }
                    this.ddEstrategiasLoading = false;
                })
                .catch(() => {
                    this.estrategiasCooperante = [];
                    this.ddEstrategiasLoading = false;
                });
        },

        mostrarModalEstrategia(){
        
            this.dialogRegistroEstrategia=true;
        },


        //Obtener los tipos de fuente cooperante
        async obtenerTiposFuenteCooperante() {
            this.tiposFuenteCooperante = [];

            await this.$store
                .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'TiposFuenteCooperante/all/1'})
                .then(res => {
                if (res.status === 200) {
                    this.tiposFuenteCooperante = res.data;
                }
                })
                .catch(() => {
                    this.tiposFuenteCooperante = [];
                });
            },


        async obtenerFuentesBilaterales() {
            this.fuentesBilaterales = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'FuentesBilaterales/all/1/0'})
            .then(res => {
            if (res.status === 200) {
                this.fuentesBilaterales = res.data;
            }
            })
            .catch(() => {
            this.fuentesBilaterales = [];
            });
        },


        //Obtener las agencias por fuente bilateral
        async obtenerAgenciasPorFuenteBilateral() {
            this.agencias = [];
            this.ddAgenciasLoading=true;
            await this.$store
            .dispatch(OBTENER_SUBITEMS_CATALOGO, {endpoint: 'Agencias/all/1', id: this.fuenteBilateralSeleccionada.id})
            .then(res => {
            if (res.status === 200) {
                this.ddAgenciasLoading=false;
                this.agencias = res.data;
            }
            })
            .catch(() => {
            this.ddAgenciasLoading=false;
            this.agencias = [];
            });
        },


        async obtenerFuentesMultilateral() {
          this.fuentesMultilateral = [];
          await this.$store.dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'FuenteMultiLateral/all/1'})
              .then(res => {
                if (res.status === 200) {
                    this.fuentesMultilateral = res.data;
                }
              })
              .catch(() => {
                  this.fuentesMultilateral = [];
              });
        },



        //Registrar un socio implementador
        async registrarEstrategia(){
            this.btnRegistroEstrategiaLoading = true;

            //!Asignar el estado a datos empresa segun lo seleccionado en el swithc del formulario
            /* this.switchItemEstado
                ? (this.datosItem.estadosId = 1)
                : (this.datosItem.estadosId = 2); */


            
            this.datosEstrategia.montoUSD = parseFloat(this.montoMonedaUsd);
            this.datosEstrategia.tipoCambio = parseFloat(this.tipoCambio);


            this.datosEstrategia.estadosId = 1;
            this.datosEstrategia.tiposFuenteCooperanteId = this.tipoFuenteCooperanteSeleccionada.id;

            if(this.tipoFuenteCooperanteSeleccionada.id === 1){
              this.datosEstrategia.fuentesBilateralesId = this.fuenteBilateralSeleccionada.id;
              this.datosEstrategia.fuentesMultilateralesId = null;
              if(this.agenciaSeleccionada.id && this.agenciaSeleccionada.id >0){
                this.datosEstrategia.agenciasId = this.agenciaSeleccionada.id;
              }
            } else {
              this.datosEstrategia.fuentesBilateralesId = null;
              this.datosEstrategia.agenciasId = null;
              this.datosEstrategia.fuentesMultilateralesId = this.fuenteMultilateralSeleccionada.id;
            }


            this.datosEstrategia.fechaInicio = moment(this.fechaInicio, "DD-MM-YYYY").format("YYYY-MM-DD");
            this.datosEstrategia.fechaFinalizacion = moment(this.fechaFinalizacion, "DD-MM-YYYY").format("YYYY-MM-DD");

            let dispatch = REGISTRAR_ESTRATEGIA_CNSC;

            await this.$store
            .dispatch(dispatch, this.datosEstrategia)
            .then(res => {
                if (res.status === 200) {
                    //this.nombreInstitucion = this.datosItem.nombreInstitucion;
                    this.$refs.snackalert.SnackbarShow("success","Mensaje",res.message);
                    this.obtenerEstrategias();
                    this.dialogRegistroEstrategia = false;

                    this.resetFormEstrategia();
                    //this.tab = "tab-2";
                    //this.accion = 2;
                    this.tabContactosDisabled=false;
                } else {
                    this.$refs.snackalert.SnackbarShow("warning","Alerta",res.message);
                }
                this.btnRegistroEstrategiaLoading = false;
            })
            .catch(() => {
                this.$refs.snackalert.SnackbarShow("warning","Alerta",`Ha ocurrido un error durante el registro.`);
                this.btnRegistroEstrategiaLoading = false;
            });
            
        },


       
        //Obtener la información de cobertura
        async obtenerDatosSeccion(){

            this.datosItem = {};
            this.cardDatosLoading = true;
         
            this.$store
                .dispatch(OBTENER_DATOS_SECCIONES_PROYECTO, {seccion: 'CoberturaProyecto', id: 1})
                .then(() => {
                    this.datosItem = this.$store.state.proyectocns.datosSeccion;
                    this.cardDatosLoading = false;
                    this.condicionDesembolsos.id = this.datosItem.condicionesDesembolsos;
                })
                .catch(() => {
                    this.cardDatosLoading = false;
                });

        },


        //Sectores beneficiados
        async obtenerSectoresBeneficiados() {
            this.sectoresBeneficiadosLoading=true;
            this.sectoresBeneficiados = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'Sectores/all/1/2'})
            .then(res => {
                if (res.status === 200) {
                    this.sectoresBeneficiados = res.data;

                }
                this.sectoresBeneficiadosLoading=false;
            })
            .catch(() => {
                this.sectoresBeneficiados = [];
                this.sectoresBeneficiadosLoading=false;
            });
        
        },

        //Obtener los departamentos
        async obtenerDepartamentos() {
            this.departamentosLoading=true;
            this.departamentosActividadLoading=true;
            this.departamentos = [];
            this.departamentosActividad = [];
            await this.$store
            .dispatch(OBTENER_DEPARTAMENTOS_PAIS, 94) //94 representa al país 94 (Guatemala)
            .then(res => {
                if (res.status === 200) {
                    this.departamentos = res.data;
                    this.departamentosActividad = res.data;

                }
                this.departamentosLoading=false;
                this.departamentosActividadLoading=false;
            })
            .catch(() => {
                this.departamentos = [];
                this.departamentosActividad = [];
                this.departamentosLoading=false;
                this.departamentosActividadLoading=false;
            });
        
        },

        //Obtener municipios por departamento
        async obtenerMunicipiosPorDepartamento(departamentosId, tipo){
            
            this.municipiosLoading=true;
            this.datosItem.municipiosId=null;
            let deptoId = 0;
            tipo=== 1 ? this.municipios = [] : this.municipiosActividad = [];
            deptoId = departamentosId;

            await this.$store
            .dispatch(OBTENER_MUNICIPIOS_DEPARTAMENTO, deptoId) //1 representa al país 1 (Guatemala)
            .then(res => {
                if (res.status === 200) {
                    tipo=== 1 ? this.municipios = res.data : this.municipiosActividad = res.data;
                }
                tipo=== 1 ? this.municipiosLoading = false : this.municipiosActividadLoading = false;
            })
            .catch(() => {
                tipo=== 1 ? this.municipios = [] : this.municipiosActividad = [];
                tipo=== 1 ? this.municipiosLoading = false : this.municipiosActividadLoading = false;
                
            });
        },

       
         //obtener las PNDS
        async obtenerPnds() {
            this.ddPndLoading=true;
            this.pnds = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'PND/all/1'})
            .then(res => {
                if (res.status === 200) {
                    this.pnds = res.data;

                }
                this.ddPndLoading=false;
            })
            .catch(() => {
                this.pnds = [];
                this.ddPndLoading=false;
            });
        
        },

        //obtenerMetasPnd por pnd id
        async obtenerMetasPnd(pndId){
             this.ddMetasPndLoading=true;
            this.metasPnd = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `MetaPND/all/1/${pndId}`})
            .then(res => {
                if (res.status === 200) {
                    this.metasPnd = res.data;

                }
                this.ddMetasPndLoading=false;
            })
            .catch(() => {
                this.pnds = [];
                this.ddMetasPndLoading=false;
            });
        },


        //Obtener las ods
        async obtenerOds(){
             this.ddOdsLoading=true;
            this.ods = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `ODS/all/1`})
            .then(res => {
                if (res.status === 200) {
                    this.ods = res.data;

                }
                this.ddOdsLoading=false;
            })
            .catch(() => {
                this.pnds = [];
                this.ddOdsLoading=false;
            });
        },


        //Obtener metas ods por ods id
        async obtenerMetasOds(odsId){
             this.ddMetasOdsLoading=true;
            this.metasOds = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `MetasODS/all/1/${odsId}`})
            .then(res => {
                if (res.status === 200) {
                    this.metasOds = res.data;

                }
                this.ddMetasOdsLoading=false;
            })
            .catch(() => {
                this.metasOds = [];
                this.ddMetasOdsLoading=false;
            });
        },
        

        //Obtener los ejes katun
        async obtenerEjesKatun(){
             this.ddEjesKatunLoading=true;
            this.ejesKatun = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `EjesKatun/all/1`})
            .then(res => {
                if (res.status === 200) {
                    this.ejesKatun = res.data;

                }
                this.ddEjesKatunLoading=false;
            })
            .catch(() => {
                this.ejesKatun = [];
                this.ddEjesKatunLoading=false;
            });
        },

        //Obtener las metas katun
        async obtenerPrioridadesKatun(katunId){
            if(!this.datosCobertura.ejesKatunId || this.datosCobertura.ejesKatunId == 0 || this.datosCobertura.ejesKatunId == null ){
                this.datosCobertura.prioridadesKatunId = null;
                this.datosCobertura.metasKatunId = null;
            }

            this.ddPrioridadesKatunLoading=true;
            this.prioridadesKatun = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `PrioridadesKatun/all/1/${katunId}`})
            .then(res => {
                if (res.status === 200) {
                    this.prioridadesKatun = res.data;

                }
                this.ddPrioridadesKatunLoading=false;
            })
            .catch(() => {
                this.prioridadesKatun = [];
                this.ddPrioridadesKatunLoading=false;
            });
        },


        //Obtener las metas katun
        async obtenerMetasKatun(prioridadKatunId){
             this.ddMetasKatunLoading=true;
             this.datosCobertura.metasKatunId=0;
            this.metasKatun = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `MetasKatun/all/1/${prioridadKatunId}`})
            .then(res => {
                if (res.status === 200) {
                    this.metasKatun = res.data;

                }
                this.ddMetasKatunLoading=false;
            })
            .catch(() => {
                this.prioridadesKatun = [];
                this.ddMetasKatunLoading=false;
            });
        },


        //Obtener pgg
        async obtenerPgg(){
            this.pggs = [];
            this.pilaresPgg = [];
            this.metasEstrategicasPgg = [];
            this.objetivosEstrategicosPgg = [];
            this.objetivosSectorialesPgg = [];

            this.pggSeleccionada={};
            this.ddPggLoading=true;
            
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `PGG/all/1`})
            .then(res => {
                if (res.status === 200) {
                    this.pggs = res.data;

                }
                this.ddPggLoading=false;
            })
            .catch(() => {
                this.pggs = [];
                this.ddPggLoading=false;
            });
        },

        //Obtener los pilares segun pgg
        async obtenerPilaresPgg(pggId){
            this.pilaresPgg = [];
            this.metasEstrategicasPgg = [];
            this.objetivosEstrategicosPgg = [];
            this.objetivosSectorialesPgg = [];

            this.pilarPggSeleccionado = {};
            this.ddPilaresPggLoading=true;
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `PilarPGG/all/1/${pggId}`})
            .then(res => {
                if (res.status === 200) {
                    this.pilaresPgg = res.data;

                }
                this.ddPilaresPggLoading=false;
            })
            .catch(() => {
                this.pilaresPgg = [];
                this.ddPilaresPggLoading=false;
            });
        },

        //Obtener las metas estrategicas pgg por pilar pgg id
        async obtenerMetasEstrategicas(pilarPggId){
            this.metaEstrategicaPggSeleccionada = {};
            this.ddMetasEstrategicasPggLoading=true;
            this.objetivosEstrategicosPgg = [];
            this.objetivosSectorialesPgg = [];
            this.metasEstrategicasPgg = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `MetasEstrategicasPGG/all/1/${pilarPggId}`})
            .then(res => {
                if (res.status === 200) {
                    this.metasEstrategicasPgg = res.data;

                }
                this.ddMetasEstrategicasPggLoading=false;
            })
            .catch(() => {
                this.metasEstrategicasPgg = [];
                this.ddMetasEstrategicasPggLoading=false;
            });
        },


        //Obtener los objetivos estrategicos PGG por meta PGG id
       /*  async obtenerObjetivosEstrategicosPgg(metaEstrategicaPggId){
            this.objetivoEstrategicoPggSeleccionado ={};
            this.ddObjetivosEstrategicosPggLoading=true;
            this.objetivosEstrategicosPgg = [];
            this.objetivosSectorialesPgg = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `ObjetivosEstrategicosPGG/all/1/${metaEstrategicaPggId}`})
            .then(res => {
                if (res.status === 200) {
                    this.objetivosEstrategicosPgg = res.data;

                }
                this.ddObjetivosEstrategicosPggLoading=false;
            })
            .catch(() => {
                this.objetivosEstrategicosPgg = [];
                this.ddObjetivosEstrategicosPggLoading=false;
            });


        }, */

        //Obteener los objetivos secotriales PGG por objetivo estrategico pgg Id
        async obtenerObjetivosSectorialessPgg(objetivoEstrategicoId){
            this.ddObjetivosSectorialesPggLoading=true;
            //this.datosCobertura.ObjetivosSectorialesPGGId =0;
            this.objetivosSectorialesPgg = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `ObjetivosSectorialesPGG/all/1/${objetivoEstrategicoId}`})
            .then(res => {
                if (res.status === 200) {
                    this.objetivosSectorialesPgg = res.data;

                }
                this.ddObjetivosSectorialesPggLoading=false;
            })
            .catch(() => {
                this.objetivosSectorialesPgg = [];
                this.ddObjetivosSectorialesPggLoading=false;
            });
        },



        //Obtener CAD
        async obtenerCad(){
            this.cads = [];
            this.cadsLoading = true;
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `CAD/all/1`})
            .then(res => {
                if (res.status === 200) {
                    this.cads = res.data;

                }
                this.cadsLoading=false;
            })
            .catch(() => {
                this.cads = [];
                this.cadsLoading=false;
            });
        },


        //Obtener CRS por Cad
        async obtenerCrsPorCadSeleccionado(){

            this.crsLoading=true;
            this.crs = [];
            
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `CRS/all/1/${this.cadSeleccionado.id}`})
            .then(res => {
                if (res.status === 200) {
                    this.crs = res.data;

                }
                this.crsLoading=false;
            })
            .catch(() => {
                this.crs = [];
                this.crsLoading=false;
            });

        },


        //Obtener las politicas públicas
        async obtenerPoliticasPublicas(){
            
            
            this.politicaPublicaLoading=true;
            this.politicasPublicas = [];
            
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `PoliticaPublica/all/1`})
            .then(res => {
                if (res.status === 200) {
                    this.politicasPublicas = res.data;

                }
                this.politicaPublicaLoading=false;
            })
            .catch(() => {
                this.politicasPublicas = [];
                this.politicaPublicaLoading=false;
            });

        },



        //Actualizar la clasificacion OCDE 
        async actualizarClasificacionOCDE(){
            this.btnRegistroClasificacionLoading=true;
            //this.datosCuentaDesembolsos.proyectosCNSId = this.id;
            //this.cuentaCondicionDesembolsoSeleccionada.id === 2 ? this.datosCuentaDesembolsos.proyectaDesembolsos = 0 : this.datosCuentaDesembolsos.proyectaDesembolsos = 1;  
            await this.$store
            .dispatch(ACTUALIZAR_CLASIFICACION_OCDE_CNSC, {id: this.id, crsId: this.crsSeleccionado.id, usuarioActualizacion: "admin"})
            .then(res => {
                if (res.status === 200) {
                    this.$refs.snackalert.SnackbarShow("success", "Mensaje", res.message);
                } else {
                    this.$refs.snackalert.SnackbarShow("warning", "Alerta", res.message);
                }
                this.btnRegistroClasificacionLoading=false;
            })
            .catch(error => {
                
                 this.btnRegistroClasificacionLoading = false;
                 this.$refs.snackalert.SnackbarShow("warning", "Alerta", `Ha ocurrido un error durante el registro. ${error}`
                );
            });
        },


        //Actualizar la política pública
        async actualizarPoliticaPublica(){
            this.btnRegistroPoliticaLoading=true;
            //this.datosCuentaDesembolsos.proyectosCNSId = this.id;
            //this.cuentaCondicionDesembolsoSeleccionada.id === 2 ? this.datosCuentaDesembolsos.proyectaDesembolsos = 0 : this.datosCuentaDesembolsos.proyectaDesembolsos = 1;  

            this.datosPoliticaPublica.politicaPublicaId = this.politicaPublicaSeleccionada.id;
            await this.$store
            .dispatch(ACTUALIZAR_POLITICA_PUBLICA_CNSC, {id: this.id, datos: this.datosPoliticaPublica})
            .then(res => {
                if (res.status === 200) {
                    this.$refs.snackalert.SnackbarShow("success", "Mensaje", res.message);
                } else {
                    this.$refs.snackalert.SnackbarShow("warning", "Alerta", res.message);
                }
                this.btnRegistroPoliticaLoading=false;
            })
            .catch(error => {
                
                 this.btnRegistroPoliticaLoading = false;
                 this.$refs.snackalert.SnackbarShow("warning", "Alerta", `Ha ocurrido un error durante el registro. ${error}`
                );
            });
        },



        //Actualizar el campo de "El proyecto es parte de un programa conjunto"
        async actualizarParteCooperacion(){
            
            let parteCooperacion = 0; 
            if(this.parteMarcoCooperacion.id === 1){
                parteCooperacion = 1;
            } else {
                parteCooperacion = 0;
            }

            

            this.btnRegistroParteLoading=true;
            //this.datosCuentaDesembolsos.proyectosCNSId = this.id;
            //this.cuentaCondicionDesembolsoSeleccionada.id === 2 ? this.datosCuentaDesembolsos.proyectaDesembolsos = 0 : this.datosCuentaDesembolsos.proyectaDesembolsos = 1;  
            await this.$store
            .dispatch(ACTUALIZAR_PARTE_COOPERACION_ESTRATEGIA_CNSC, {id: this.id, parteDeCooperacion: parteCooperacion, usuarioActualizacion: "admin"})
            .then(res => {
                //this.dialogLoaderVisible = false;
                //console.log(res)
                if (res.status === 200) {
                    this.$refs.snackalert.SnackbarShow("success", "Mensaje", res.message);
                    if(this.parteMarcoCooperacion.id === 2){
                        this.habilitarEstrategias = false;    
                    }
                    else{
                        this.habilitarEstrategias = true;
                    }
                } else {
                    this.$refs.snackalert.SnackbarShow("warning", "Alerta", res.message);
                    this.habilitarEstrategias = false;
                }
                this.btnRegistroParteLoading=false;
            })
            .catch(error => {
                
                 this.btnRegistroParteLoading = false;
                 if(this.parteMarcoCooperacion.id === 2){
                        this.habilitarEstrategias = false;    
                    }
                    else{
                        this.habilitarProyeccionDesembolsos = true;
                    }
                 this.$refs.snackalert.SnackbarShow("warning", "Alerta", `Ha ocurrido un error durante el registro. ${error}`
                );
            });
            

            //this.datosParteCooperacion.
        },



        //Vincular una estrategia al proyecto
        async vincularEstrategia(){
            
            this.btnVincularEstrategiaLoading=true;
            await this.$store
                .dispatch(VINCULAR_ESTRATEGIA_PROYECTO_CNSC, {id: this.id, estrategiaCNSCId: this.estrategiaCooperanteId, usuarioActualizacion: "admin"})
                .then(res => {
                    //this.dialogLoaderVisible = false;
                    //console.log(res)
                    if (res.status === 200) {
                        this.$refs.snackalert.SnackbarShow("success", "Mensaje", res.message);
                        this.obtenerDatosProyecto(this.id);
                    } else {
                        this.$refs.snackalert.SnackbarShow("warning", "Alerta", res.message);
                    }
                    this.btnVincularEstrategiaLoading=false;
                })
                .catch(error => {
                    
                    this.btnVincularEstrategiaLoading = false;
                    this.$refs.snackalert.SnackbarShow("warning", "Alerta", `Ha ocurrido un error durante el registro. ${error}`
                    );
                });
        },
            

        //Registrar Datos de cobertura
        async registrarCobertura(){
            this.btnRegistroLoading=true;
            this.datosCobertura.estadosId= 1;
            this.datosCobertura.usuarioCreacion ='admin';
            this.datosCobertura.proyectoCNSCId = this.id;

            //Validar monto que sea mayor a cero 00
            if(parseFloat(this.datosCobertura.monto) <= 0){
                this.$refs.snackalert.SnackbarShow("warning", "Alerta", "El monto debe ser mayor a 0.00");
            }

            let dispatch = REGISTRAR_COBERTURA_CNS;

            if(this.accionVinculacion===2){
                dispatch = ACTUALIZAR_COBERTURA_CNS;
            }



            //Limpiar los campos

            if(!this.datosCobertura.pndId || this.datosCobertura.pndId === 0 || this.datosCobertura.pndId == null){
                this.datosCobertura.pndId = null;
                this.datosCobertura.metaPNDId = null;
            }

            if(!this.datosCobertura.metaPNDId || this.datosCobertura.metaPNDId === 0 || this.datosCobertura.metaPNDId == null){
                this.datosCobertura.metaPNDId = null;
            }

            if(!this.datosCobertura.odsId || this.datosCobertura.odsId === 0 || this.datosCobertura.odsId == null){
                this.datosCobertura.odsId = null;
                this.datosCobertura.metasODSId = null;
            }


            if(!this.datosCobertura.metasODSId || this.datosCobertura.metasODSId === 0 || this.datosCobertura.metasODSId == null){
                this.datosCobertura.metasODSId = null;
            }

           
            if(!this.datosCobertura.pggId || this.datosCobertura.pggId === 0 || this.datosCobertura.pggId == null){
                this.datosCobertura.pggId = null;
                this.datosCobertura.pilarPGGId = null
            }

            if(!this.datosCobertura.ejesKatunId || this.datosCobertura.ejesKatunId === 0 || this.datosCobertura.ejesKatunId == null){
                this.datosCobertura.ejesKatunId = null
                this.datosCobertura.prioridadesKatunId = null;
                this.datosCobertura.metasKatunId = null;
            }

            if(!this.datosCobertura.prioridadesKatunId || this.datosCobertura.prioridadesKatunId === 0 || this.datosCobertura.prioridadesKatunId == null){
                this.datosCobertura.prioridadesKatunId = null;
                this.datosCobertura.metasKatunId = null;
            }

            if(!this.datosCobertura.metasKatunId || this.datosCobertura.metasKatunId === 0 || this.datosCobertura.metasKatunId == null){
                this.datosCobertura.metasKatunId = null;
            }


           // console.log(this.datosCobertura)


            



            //console.log(this.datosCobertura);
           
            await this.$store
            .dispatch(dispatch, {datos: this.datosCobertura})
            .then(res => {
                
                //this.dialogLoaderVisible = false;
                if (res.status === 200) {
                    this.$refs.snackalert.SnackbarShow("success", "Mensaje", res.message);
                    this.obtenerCoberturaProyecto(this.id);             
                    //this.resetItems(2);
                } else {
                    this.$refs.snackalert.SnackbarShow("warning", "Alerta", res.message);
                }
                this.btnRegistroLoading=false;
            })
            .catch(error => {
                
                 this.btnRegistroLoading=false;
                 this.$refs.snackalert.SnackbarShow("warning", "Alerta", `Ha ocurrido un error durante el registro. ${error}`
                );
            });
        },

        //Eliminar registro de cobertura
        async eliminarItem(id){
            this.btnEliminarCoberturaDisabled = true;

            await this.$store
            .dispatch(ELIMINAR_COBERTURA_CNS, id)
            .then(res => {
                
                //this.dialogLoaderVisible = false;
                if (res.status === 200) {
                    this.$refs.snackalert.SnackbarShow("success", "Mensaje", res.message);
                    this.obtenerCoberturaProyecto(this.id);             
                   // this.resetItems();
                } else {
                    this.$refs.snackalert.SnackbarShow("warning", "Alerta", res.message);
                }
                this.btnEliminarCoberturaDisabled=false;
            })
            .catch(error => {
                
                 this.btnEliminarCoberturaDisabled=false;
                 this.$refs.snackalert.SnackbarShow("warning", "Alerta", `Ha ocurrido un error durante el registro. ${error}`
                );
            });
        },

        /*
        // Obtener los registros de cobertura almacenados para el proyecto
        */
        async obtenerCoberturaProyecto(proyectoId){
            //Obtener los ods setOdsSecundarios
             if(this.$refs.AsigOdsSecundarioComponent){
                this.$refs.AsigOdsSecundarioComponent.obtenerOds();
                this.$refs.AsigOdsSecundarioComponent.obtenerOdsRegistrados(1, this.id);

             }

            this.tableCoberturaLoading=true;
            this.coberturas = [];
            await this.$store
            .dispatch(OBTENER_COBERTURAS_CNS, {tipo: 4, id: proyectoId})
            .then(res => {
                if (res.status === 200) {
                    this.coberturas = res.data;
                    this.datosCobertura.id = this.coberturas[0].id;

                    if(this.vinculacionRegistrada==false){
                        this.datosCobertura.pndId = this.coberturas[0].pndId;
                        this.obtenerMetasPnd(this.datosCobertura.pndId).then(()=>{
                            this.datosCobertura.metaPNDId = this.coberturas[0].metaPNDId;
                        });
                        
                        this.datosCobertura.odsId = this.coberturas[0].odsId;
                        this.obtenerMetasOds(this.datosCobertura.odsId).then(()=>{
                            this.datosCobertura.metasODSId = this.coberturas[0].metasODSId;
                        });
                        
                        this.datosCobertura.odsSecundarioId = this.coberturas[0].odsSecundarioId;
                        this.datosCobertura.ejesKatunId = this.coberturas[0].ejesKatunId;
                        this.obtenerPrioridadesKatun(this.datosCobertura.ejesKatunId).then(()=>{
                            this.datosCobertura.prioridadesKatunId = this.coberturas[0].prioridadesKatunId;

                            this.obtenerMetasKatun(this.datosCobertura.prioridadesKatunId).then(()=>{
                                this.datosCobertura.metasKatunId = this.coberturas[0].metasKatunId;
                            });

                        });

                    
                        this.datosCobertura.pggId = this.coberturas[0].pggId;
                        this.obtenerPilaresPgg(this.datosCobertura.pggId).then(()=>{
                            this.datosCobertura.pilarPGGId = this.coberturas[0].pilarPGGId;
                        })
                    }
                    

                    this.vinculacionRegistrada = true;
                    this.accionVinculacion = 2;

                } else {
                    this.vinculacionRegistrada = false;
                    this.accionVinculacion = 1;
                }
                this.tableCoberturaLoading=false;
            })
            .catch(() => {
                 this.vinculacionRegistrada = false;
                this.coberturas = [];
                this.tableCoberturaLoading=false;
            });
        },


        //Obtener Acvitidades
        async obtenerActividades() {
            this.actividadesLoading = true;
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'Actividades'})
            .then(res => {
                if (res.status === 200) {
                    this.actividades = res.data;

                }
                this.actividadesLoading=false;
            })
            .catch(() => {
                this.actividades = [];
                this.actividadesLoading=false;
            });
        }


        

    },

    created() {
        this.resetItems(1);
        this.obtenerDatosProyecto(this.id);
        this.obtenerSectoresBeneficiados();
        this.obtenerDepartamentos();
        this.obtenerPnds();
        this.obtenerOds();
        this.obtenerEjesKatun();
        this.obtenerPgg();
        this.obtenerActividades();
        this.obtenerCoberturaProyecto(this.id);

        this.obtenerTiposFuenteCooperante();
        this.obtenerFuentesBilaterales();
        //this.obtenerAgenciasPorFuenteBilateral();
        this.obtenerFuentesMultilateral();
        this.obtenerEstrategias();
        
        
    },

     watch:{

        montoMonedaUsd : function(){
            this.datosEstrategia.montoQuetzales = (this.montoMonedaUsd * this.tipoCambio);
        },

        tipoCambio : function() {
            this.datosEstrategia.montoQuetzales = (this.montoMonedaUsd * this.tipoCambio);
        }

    },

    computed: {

        headersCoberturas(){
            return [
                 {
                    text: "Id",
                    //align: "start",
                    sortable: false,
                    value: "id",
                    align: ' d-none'
                },
               /*  {
                    text: "Municipio / Departamento",
                    align: "start",
                    sortable: true,
                    value: "nombreMunicipio"
                }, */
                {
                    text: "Prioridad Nacional de Desarrollo",
                    align: "start",
                    sortable: true,
                    value: "nombre"
                },
                {
                    text: "Objetivo de desarrollo sostenible",
                    align: "start",
                    sortable: true,
                    value: "correo"
                },
                {
                    text: "K`atun",
                    align: "start",
                    sortable: true,
                    value: "metaKatun"
                },
               /*  {
                    text: "Monto suscrito",
                    align: "start",
                    sortable: true,
                    value: "monto"
                }, */
               /*  {
                    text: "Acciones",
                    align: "start",
                    sortable: true,
                    value: ""
                }, */
            ]
        }

    }


}

</script>