var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.skeletonLoading)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-skeleton-loader',{attrs:{"min-height":"600px","type":"article, article, article, actions"}})],1)],1)],1):_vm._e(),(!_vm.skeletonLoading)?_c('div',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.registrarItem.apply(null, arguments)}},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-3",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-h5 black--text"},[_vm._v(" Datos generales del Proyecto ")])],1)],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-textarea',{staticClass:"required",attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Nombre del programa/proyecto/acción","rules":[
              _vm.required('Nombre del programa/proyecto/acción'),
              _vm.minLength('Nombre del programa/proyecto/acción', 5),
              _vm.maxLength('Nombre del programa/proyecto/acción', 500)
            ],"rows":"3","maxlength":"500","disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) || _vm.confirmacion},model:{value:(_vm.datosItem.nombreProyecto),callback:function ($$v) {_vm.$set(_vm.datosItem, "nombreProyecto", $$v)},expression:"datosItem.nombreProyecto"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0 mt-2",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.estadosProyecto,"loading":_vm.ddlEstadosLoading,"dense":"","disabled":false,"filled":"","label":"Estado del proyecto","item-text":"nombreEstado","item-value":"id","no-data-text":_vm.estadosProyecto != null
                ? 'Seleccione un estado para el proyecto'
                : 'No se han encontrado estados para el proyecto',"rules":[_vm.selectRequired('Estado del proyecto')],"menu-props":"offset-y"},model:{value:(_vm.datosItem.estadosProyectoAclId),callback:function ($$v) {_vm.$set(_vm.datosItem, "estadosProyectoAclId", $$v)},expression:"datosItem.estadosProyectoAclId"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-menu',{ref:"menuFechaSuscripcionDonacion",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"dense":"","filled":"","color":"blue-grey lighten-2","readonly":"","label":"Fecha de suscripción de la donación","hint":"DD/MM/AAAA","persistent-hint":"","append-icon":"mdi-calendar","rules":[
                  _vm.required('Fecha de suscripción de la donación'),
                  _vm.dateFormat('Fecha de suscripción de la donación')
                ],"maxlength":"10","disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) ||
                    _vm.confirmacion},model:{value:(_vm.computedDateFormattedSuscripcion),callback:function ($$v) {_vm.computedDateFormattedSuscripcion=$$v},expression:"computedDateFormattedSuscripcion"}},'v-text-field',attrs,false),on))]}}],null,false,2412663752),model:{value:(_vm.menuFechaSuscripcionDonacion),callback:function ($$v) {_vm.menuFechaSuscripcionDonacion=$$v},expression:"menuFechaSuscripcionDonacion"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":"es"},on:{"input":function($event){_vm.menuFechaSuscripcionDonacion = false}},model:{value:(_vm.dateSuscripcion),callback:function ($$v) {_vm.dateSuscripcion=$$v},expression:"dateSuscripcion"}})],1)],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-menu',{ref:"menuFechaInicio",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"dense":"","filled":"","color":"blue-grey lighten-2","readonly":"","label":"Fecha de inicio","hint":"DD/MM/AAAA","persistent-hint":"","append-icon":"mdi-calendar","rules":[
                  _vm.required('Fecha de inicio'),
                  _vm.dateFormat('Fecha de inicio')
                ],"maxlength":"10","disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) ||
                    _vm.confirmacion},model:{value:(_vm.computedDateFormattedInicio),callback:function ($$v) {_vm.computedDateFormattedInicio=$$v},expression:"computedDateFormattedInicio"}},'v-text-field',attrs,false),on))]}}],null,false,2398197835),model:{value:(_vm.menuFechaInicio),callback:function ($$v) {_vm.menuFechaInicio=$$v},expression:"menuFechaInicio"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":"es"},on:{"input":function($event){_vm.menuFechaInicio = false}},model:{value:(_vm.dateInicio),callback:function ($$v) {_vm.dateInicio=$$v},expression:"dateInicio"}})],1)],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-menu',{ref:"menuFechaFinalizacion",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"dense":"","filled":"","color":"blue-grey lighten-2","readonly":"","label":"Fecha de finalización","hint":"DD/MM/AAAA","persistent-hint":"","append-icon":"mdi-calendar","rules":[
                  _vm.required('Fecha de finalización'),
                  _vm.dateFormat('Fecha de finalización')
                ],"maxlength":"10","disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) ||
                    _vm.confirmacion},model:{value:(_vm.computedDateFormattedFinalizacion),callback:function ($$v) {_vm.computedDateFormattedFinalizacion=$$v},expression:"computedDateFormattedFinalizacion"}},'v-text-field',attrs,false),on))]}}],null,false,1489321559),model:{value:(_vm.menuFechaFinalizacion),callback:function ($$v) {_vm.menuFechaFinalizacion=$$v},expression:"menuFechaFinalizacion"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":"es"},on:{"input":function($event){_vm.menuFechaFinalizacion = false}},model:{value:(_vm.dateFin),callback:function ($$v) {_vm.dateFin=$$v},expression:"dateFin"}})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-textarea',{staticClass:"required",attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Objetivo general","rules":[
              _vm.required('objetivo general'),
              _vm.minLength('objetivo general', 10),
              _vm.maxLength('objetivo general', 2000)
            ],"maxlength":"2000","disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) || _vm.confirmacion},model:{value:(_vm.datosItem.objetivoGeneral),callback:function ($$v) {_vm.$set(_vm.datosItem, "objetivoGeneral", $$v)},expression:"datosItem.objetivoGeneral"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-textarea',{staticClass:"required",attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Objetivos específicos","rules":[
              _vm.required('objetivos específicos'),
              _vm.minLength('objetivos específicos', 10),
              _vm.maxLength('objetivos específicos', 2000)
            ],"maxlength":"2000","disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) || _vm.confirmacion},model:{value:(_vm.datosItem.objetivosEspecificos),callback:function ($$v) {_vm.$set(_vm.datosItem, "objetivosEspecificos", $$v)},expression:"datosItem.objetivosEspecificos"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-textarea',{staticClass:"required",attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Resultados esperados","rules":[
              _vm.required('Resultados esperados'),
              _vm.minLength('Resultados esperados', 10),
              _vm.maxLength('Resultados esperados', 2000)
            ],"maxlength":"2000","disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) || _vm.confirmacion},model:{value:(_vm.datosItem.resultados),callback:function ($$v) {_vm.$set(_vm.datosItem, "resultados", $$v)},expression:"datosItem.resultados"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0 mt-2",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.sectoresBeneficiados,"loading":false,"dense":"","filled":"","label":"Sector principal al que aporta","item-text":"sector","item-value":"id","no-data-text":_vm.sectoresBeneficiados != null
                ? 'Seleccione un sector beneficiado'
                : 'No se han encontrado sectores beneficiado',"menu-props":"offset-y","disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) || _vm.confirmacion},model:{value:(_vm.datosItem.sectoresId),callback:function ($$v) {_vm.$set(_vm.datosItem, "sectoresId", $$v)},expression:"datosItem.sectoresId"}})],1)],1),_c('v-card',{staticClass:"mt-6 elevation-0",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-h5 font-weight-bolder text-dark"},[_vm._v(" Documentos del proyecto ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-4 pb-0",attrs:{"cols":"12","md":"8","sm":"7","xs":"12"}},[[_c('v-file-input',{ref:"respaldoFile",class:_vm.accion === 1 ? "required" : "",attrs:{"id":"respaldoFile","dense":"","filled":"","placeholder":"Seleccionar documento que respalda el proyecto","label":"Documento de respaldo del proyecto","append-icon":"mdi-file-pdf-outline","prepend-icon":"","accept":"application/pdf,image/jpeg","rules":[
                    _vm.accion === 1
                      ? _vm.fileRequired('Documento de respaldo del proyecto')
                      : true
                  ],"show-size":1000,"disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) ||
                      _vm.confirmacion},on:{"change":function($event){return _vm.onFileChange('respaldo', $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                      var index = ref.index;
                      var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")]):(index === 2)?_c('span',{staticClass:"\n                          text-overline\n                          grey--text\n                          text--darken-3\n                          mx-2\n                          "},[_vm._v(" +"+_vm._s(_vm.files.length - 2)+" archivo(s) ")]):_vm._e()]}}],null,false,1956601073)})]],2),(_vm.documentoRespaldoRegistrado == true && _vm.accion === 2)?_c('v-col',{staticClass:"pt-6 pb-6",attrs:{"cols":"12","md":"4","sm":"5","xs":"12"}},[_c('v-btn',{staticClass:"mb-1 float-right white--text",attrs:{"color":"light-blue-502","depressed":""},on:{"click":function($event){return _vm.descargarArchivo(_vm.datosItem.pathRespaldoProyecto)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v(" Descargar ")],1)],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"8","sm":"7","xs":"12"}},[[_c('v-file-input',{ref:"suscripcionFile",class:_vm.accion === 1 ? "required" : "",attrs:{"id":"suscripcionFile","dense":"","filled":"","placeholder":"Seleccionar documento de suscripción","label":"Documento de suscripción","append-icon":"mdi-file-pdf-outline","prepend-icon":"","accept":"application/pdf,image/jpeg","rules":[
                    _vm.accion === 1
                      ? _vm.fileRequired('Documento de suscripción')
                      : true
                  ],"show-size":1000,"disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) ||
                      _vm.confirmacion},on:{"change":function($event){return _vm.onFileChange('suscripcion', $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                      var index = ref.index;
                      var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")]):(index === 2)?_c('span',{staticClass:"\n                          text-overline\n                          grey--text\n                          text--darken-3\n                          mx-2\n                          "},[_vm._v(" +"+_vm._s(_vm.files.length - 2)+" archivo(s) ")]):_vm._e()]}}],null,false,1956601073)})]],2),(_vm.documentoSuscripcionRegistrado == true && _vm.accion === 2)?_c('v-col',{staticClass:"pt-6 pb-6",attrs:{"cols":"12","md":"4","sm":"5","xs":"12"}},[_c('v-btn',{staticClass:"mb-1 float-right white--text",attrs:{"color":"light-blue-502","depressed":""},on:{"click":function($event){return _vm.descargarArchivo(_vm.datosItem.pathDocumentoSuscripcion)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v(" Descargar ")],1)],1):_vm._e()],1)],1)],1),(!_vm.seccionesBloqueadas || _vm.tipoUsuario == "int")?_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"white--text mb-2 float-right",attrs:{"color":"light-blue darken-2","type":"submit","elevation":0,"disabled":!_vm.validForm || (_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas),"loading":_vm.btnRegistroLoading}},[_vm._v(" "+_vm._s(_vm.accion === 1 ? "Registrar información" : "Actualizar información")+" ")])],1)],1):_vm._e(),(_vm.tipo === 2)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"white--text mb-2 float-right mt-2",attrs:{"color":"light-blue-502","type":"submit","elevation":0,"disabled":!_vm.validForm,"loading":_vm.btnRegistroLoading}},[_vm._v(" "+_vm._s(_vm.btnRegistroText)+" ")])],1)],1):_vm._e()],1),_c('SnackAlert',{ref:"snackalert"})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }