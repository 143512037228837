<template>
<div>
    <div v-if="skeletonLoading">
        <v-row>
            <v-col cols="12" md="12">
                <v-skeleton-loader
                min-height="600px"
                type="article, article, article, actions"
                >
                </v-skeleton-loader>
        </v-col>
        </v-row>
    </div>

    <div v-if="!skeletonLoading">

        <v-row>
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-3">
                <v-subheader class="text-h5 black--text">
                    Datos de la institución
                </v-subheader>
            </v-col>
        </v-row>


        <!-- <v-row>                           

            <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                <v-select
                    v-model="departamentoId"
                    @change="obtenerMunicipiosPorDepartamento(departamentoId)"
                    class="required"
                    :items="departamentos"
                    :loading="departamentosLoading"
                    dense
                    filled
                    label="Departamento"
                    item-text="departamento"
                    item-value="id"
                    :no-data-text="
                        departamentos > 0
                        ? 'Selecciona un departamento'
                        : 'No se han encontrado departamentos'
                    "
                    :rules="[
                        selectRequired('departamento')
                    ]"
                    menu-props="auto"
                ></v-select>
            </v-col>

            <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                <v-select
                    v-model="municipioId"
                    :items="municipios"
                    :loading="ddMunicipiosLoading"
                    :clearable="true"
                    dense
                    class=""
                    filled
                    label="Municipio"
                    item-text="municipio"
                    item-value="id"
                    :no-data-text="
                        municipios > 0
                        ? 'Seleccione un municipio'
                        : 'No se han encontrado municipios'
                    "
                    menu-props="auto"
                    >
                </v-select>
            </v-col>
        </v-row> -->

        <v-row>
             <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">

                <v-text-field
                    dense
                    filled
                    autocomplete="off"
                    class="required"
                    color="blue-grey lighten-2"
                    v-model="datosInstitucion.nombreMaximaAutoridad"
                    label="Nombre de la máxima autoridad"
                    :rules="[
                        required('Nombre de la máxima autoridad'),
                        minLength('Nombre de la máxima autoridad', 5),
                        maxLength('Nombre de la máxima autoridad', 150)
                        
                    ]"
                    maxlength="150"
                    ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">

                <v-text-field
                    dense
                    filled
                    autocomplete="off"
                    class=""
                    color="blue-grey lighten-2"
                    v-model="datosInstitucion.telefonoInstitucional"
                    label="Número de teléfono institucional"
                    :rules="[
                        minLength('teléfono institucional', 8),
                        maxLength('teléfono institucional', 12),
                    ]"
                    maxlength="12"
                    ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">

                <v-text-field
                    dense
                    filled
                    autocomplete="off"
                    class="required"
                    color="blue-grey lighten-2"
                    v-model="datosInstitucion.correoElectronico"
                    label="Correo electrónico"
                    :rules="[
                        required('correo electrónico'),
                        minLength('correo electrónico', 5),
                        maxLength('correo electrónico', 150),
                        validEmail('correo electrónico')
                    ]"
                    maxlength="150"
                    ></v-text-field>
            </v-col>


            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">

                <v-text-field
                    dense
                    filled
                    autocomplete="off"
                    class="required"
                    color="blue-grey lighten-2"
                    v-model="datosInstitucion.paginaWeb"
                    label="Página web"
                    :rules="[
                        required('página web'),
                        minLength('página web', 5),
                        maxLength('página web', 500),
                        
                    ]"
                    maxlength="500"
                    ></v-text-field>
            </v-col>
        </v-row>

        <v-row>

            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">

                <v-text-field
                    dense
                    filled
                    autocomplete="off"
                    class="required"
                    color="blue-grey lighten-2"
                    v-model="datosInstitucion.nombreContactoEnlace"
                    label="Nombre del contacto enlace"
                    :rules="[
                        required('Nombre del contacto enlace'),
                        minLength('Nombre del contacto enlace', 5),
                        maxLength('Nombre del contacto enlace', 150)
                        
                    ]"
                    maxlength="150"
                    ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">

                <v-text-field
                    dense
                    filled
                    autocomplete="off"
                    class=""
                    color="blue-grey lighten-2"
                    v-model="datosInstitucion.telefonoContactoEnlace"
                    label="Número de teléfono del contacto enlace"
                    :rules="[
                        minLength('teléfono del contacto enlace', 8),
                        maxLength('teléfono del contacto enlace', 12),
                    ]"
                    maxlength="12"
                    ></v-text-field>
            </v-col>


        </v-row>

        <v-row>

            <v-col cols="12" md="12" sm="12" class="pt-0">
                    
                <v-btn
                    color="primary"
                    class="mb-2 float-right"
                    type="submit"
                    large
                    :elevation="0"
                    :disabled="!validForm"
                    :loading="btnRegistroContactoLoading"
                >
                    Registrar información
                </v-btn>
            </v-col>


        </v-row>

       
        <!--inicio:: condiciones de instituciones beneficiarias -->
        <v-card class="mt-6" outlined v-if="1>1">
            <v-card-text>
                             
                
                <v-form  v-if="tipo===2"
                    ref="formProyeccionDesembolsos"
                    v-on:submit.prevent="registrarContactos"
                    v-model="validForm"
                    >

                    <v-row class="mt-6">

                        <!--inicio:: campo -->
                        <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                            <v-select
                            dense
                            filled
                            v-model="tipoContactoSeleccionado"
                            :items="tiposContacto"
                            class="required"
                            color="blue-grey lighten-2"
                            label="Seleccione el tipo de contacto"
                            item-text="tipo"
                            item-value="id"
                            :rules="[selectRequired('tipo de contacto')]"
                            
                            menu-props="auto"
                            return-object
                            >
                            </v-select>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                            <v-text-field
                                dense
                                filled
                                autocomplete="off"
                                class="required"
                                color="blue-grey lighten-2"
                                v-model="datosContacto.nombre"
                                :label="tipoContactoSeleccionado.id === 8 ? 'Nombre de la máxima autoridad' : tipoContactoSeleccionado.id === 7 ? 'Nombre del gerente' : 'Nombre'"
                                :rules="[
                                    required('nombre del contacto'),
                                    minLength('nombre del contacto', 5),
                                    maxLength('nombre del contacto', 100)
                                ]"
                                maxlength="100"
                                ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">

                            <v-text-field
                                dense
                                filled
                                autocomplete="off"
                                class="required"
                                color="blue-grey lighten-2"
                                v-model="datosContacto.correoElectronicoPersonal"
                                label="Correo electrónico"
                                :rules="[
                                    required('correo electrónico'),
                                    minLength('correo electrónico', 5),
                                    maxLength('correo electrónico', 150),
                                    validEmail('correo electrónico')
                                ]"
                                maxlength="150"
                                ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">

                            <v-text-field
                                dense
                                filled
                                autocomplete="off"
                                class="required"
                                color="blue-grey lighten-2"
                                v-model="datosContacto.correoElectronicoInstitucional"
                                label="Correo electrónico institucional"
                                :rules="[
                                    required('correo electrónico'),
                                    minLength('correo electrónico', 5),
                                    maxLength('correo electrónico', 150),
                                    validEmail('correo electrónico')
                                ]"
                                maxlength="150"
                                ></v-text-field>
                        </v-col>
                    </v-row>


                    <v-row>
                        <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">

                            <v-text-field
                                dense
                                filled
                                autocomplete="off"
                                class=""
                                color="blue-grey lighten-2"
                                v-model="datosContacto.telefonoPersonal"
                                label="Número de teléfono personal"
                                :rules="[
                                    minLength('teléfono personal', 8),
                                    maxLength('teléfono personal', 12),
                                ]"
                                maxlength="12"
                                ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">

                            <v-text-field
                                dense
                                filled
                                autocomplete="off"
                                class=""
                                color="blue-grey lighten-2"
                                v-model="datosContacto.telefonoInstitucional"
                                label="Número de teléfono institucional"
                                :rules="[
                                    minLength('teléfono institucional', 8),
                                    maxLength('teléfono institucional', 12),
                                ]"
                                maxlength="12"
                                ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" sm="12" class="pt-0">
                                
                            <v-btn
                                color="primary"
                                class="mb-2 float-right"
                                type="submit"
                                large
                                :elevation="0"
                                :disabled="!validForm"
                                :loading="btnRegistroContactoLoading"
                            >
                                Registrar contacto
                            </v-btn>
                        </v-col>


                    </v-row>
                </v-form>

                <v-row class="mt-4">
                    <v-col cols="12" md="12" sm="12">
                        <v-subheader class="text-h6 black--text">
                            Contactos registrados 
                        </v-subheader>
                        <v-divider class="mt-0 pt-0"></v-divider>
                    </v-col>
                </v-row>

                <v-row>
                    <!--inicio:: tabla de documentos cargados -->
                    <v-col cols="12" md="12" sm="12" class="pt-4">
                        <v-data-table
                            class="elevation-1"
                            :headers="headersContactos"
                            :items="contactos"
                            :loading="tableContactosLoading"
                            hide-default-footer
                            :footer-props="{
                                showFirstLastPage: true,
                                firstIcon: 'mdi-page-first',
                                lastIcon: 'mdi-page-last',
                                prevIcon: 'mdi-chevron-left',
                                nextIcon: 'mdi-chevron-right',
                                'items-per-page-text': 'Registros por página',
                                pageText: '{0}-{1} de {2}'
                            }"
                            >

                                <template v-slot:item="{ item }">
                                    <tr>
                                        <td>{{ item.tipo }}</td>
                                        <td>{{ item.nombre }}</td>
                                        <td> 
                                             {{ item.correoElectronicoPersonal }}
                                            
                                        </td>
                                        <td>
                                             {{ item.correoElectronicoInstitucional }}
                                        </td>
                                        <td>
                                            <v-chip
                                                class="ma-2 font-weight-medium"
                                                label
                                                :color="
                                                    item.estadosId === 1
                                                    ? 'blue lighten-4'
                                                    : item.estadosId === 2
                                                    ? 'deep-orange lighten-4'
                                                    : item.estadosId === 6
                                                    ? 'cyan lighten-4'
                                                    : item.estadosId === 7
                                                    ? 'deep-orange lighten-4'
                                                    : 'pink lighten-4'
                                                "
                                                :text-color="
                                                    item.estadosId === 1
                                                    ? 'blue lighten-1'
                                                    : item.estadosId === 2
                                                    ? 'deep-orange lighten-1'
                                                    : item.estadosId === 6
                                                    ? 'cyan darken-1'
                                                    : item.estadosId === 7
                                                    ? 'deep-orange lighten-1'
                                                    : 'pink darken-1'
                                                "
                                                small
                                                >
                                                {{ item.estado }}
                                            </v-chip>
                                        </td>
                                        <td>
                                            <v-btn v-if="item.estadosId==1"
                                                class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                small
                                                depressed
                                                :disabled="btnEliminarDisabled"
                                                color="blue-grey lighten-5"
                                                @click="eliminarContacto(item.id)"
                                                >
                                                <v-icon left>mdi-delete</v-icon> Eliminar
                                            </v-btn>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-col>
                  </v-row>
         
            </v-card-text>
        </v-card>



        <SnackAlert ref="snackalert"></SnackAlert>
        <!-- Fin:: Snack alert-->

        <!--Dialog loader -->
        <DialogLoader
        :dialogVisible="dialogLoaderVisible"
        :text="dialogLoaderText"
        ></DialogLoader>
        <!---->
    </div>
</div>
</template>


<script>
import DialogLoader from "@/view/content/DialogLoader";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import validations from "@/core/untils/validations.js";
import {OBTENER_ITEMS_CATALOGO} from "@/core/services/store/catalogos/catalogointerno.module";
import { OBTENER_CONTACTOS, REGISTRAR_CONTACTO, ELIMINAR_CONTACTO } from "@/core/services/store/contactos/contacto.module";
import { OBTENER_DATOS_SECCIONES_PROYECTO } from "@/core/services/store/proyectoscns/proyectocns.module";
import { OBTENER_DEPARTAMENTOS_PAIS } from "@/core/services/store/catalogos/departamentos/departamento.module";
import { OBTENER_MUNICIPIOS_DEPARTAMENTO } from "@/core/services/store/catalogos/municipios/municipio.module";

export default {
    name: 'SeccionContactosProyectoCooperante',
    components: {
        DialogLoader,
        SnackAlert
    },
    props: ['id', 'tipo'], //id es el id del proyecto, el tipo 1 es segeplan (no se permite la actulizacion o registro), el tipo 2 es externo
    
    data(){
        return {
            skeletonLoading: false,
            validForm: false,
            departamentoId: 0,
            departamentos: [],
            departamentosLoading: false,
            municipioId: 0,
            municipios:[],
            ddMunicipiosLoading: false,
            datosInstitucion:{
                nombreMaximaAutoridad: "",
                telefonoInstitucional: "",
                correoElectronico: "",
                paginaWeb: "",
                nombreContactoEnlace: "",
                telefonoContactoEnlace: "",
            },

            contactos: [],
            tableContactosLoading: false,
            dialogLoaderVisible:false,
            dialogLoaderText: '',
            tiposContacto: [],
            tipoContactoSeleccionado: {},
            btnRegistroContactoLoading: false,
            btnEliminarDisabled:false,
            datosContacto: {
                nombre: '',
                cargosId: null,
                area: '',
                tipo: 2,
                proyectoCNSCId: this.id,
                correoElectronicoPersonal: null,
                telefonoPersonal: null,
                correoElectronicoInstitucional: '',
                telefonoInstitucional: null,
                estadosId: 1,
                usuarioCreacion: 'admin'
            },
            datosItem: {
                nombreGerente: '',
                correoGerente: '',
                nombreMaximaAutoridad: '',
                correoMaximaAutoridad: '',
                direccionFisicaProyecto: '',
                telefonoDirectoProyecto: '',

            },
            ...validations
        }
    },

    methods:{

        //Restablecer los campos
        //El tipo se usa para poner la animacion de todo el card (1) o solo la tabla (2)
        resetItems(tipo){
            if(tipo===1){
                this.skeletonLoading=true;
            }
            this.tableContactosLoading=true;
            this.tipoContactoSeleccionado= {};
            this.tipoContactoSeleccionado.id = 8;
            this.tipoContactoSeleccionado.tipo = "Máxima Autoridad";

            this.datosInstitucion={
                nombreMaximaAutoridad: "",
                telefonoInstitucional: "",
                correoElectronico: "",
                paginaWeb: "",
                nombreContactoEnlace: "",
                telefonoContactoEnlace: "",
            };
            
            this.datosContacto= {
                nombre: '',
                cargosId: null,
                area: '',
                tipo: 2,
                proyectoCNSCId: this.id,
                correoElectronicoPersonal: null,
                telefonoPersonal: null,
                correoElectronicoInstitucional: '',
                telefonoInstitucional: null,
                estadosId: 1,
                usuarioCreacion: 'admin'
            };
        },

        //Obtener departamentos
        async obtenerDepartamentos(){
            this.departamentosLoading=true;
            this.departamentosActividadLoading=true;
            this.departamentos = [];
            //this.departamentosActividad = [];
            await this.$store
            .dispatch(OBTENER_DEPARTAMENTOS_PAIS, 94) //94 representa al país 94 (Guatemala)
            .then(res => {
                if (res.status === 200) {
                    this.departamentos = res.data;
                    //this.departamentosActividad = res.data;

                }
                this.departamentosLoading=false;
               // this.departamentosActividadLoading=false;
            })
            .catch(() => {
                this.departamentos = [];
                //this.departamentosActividad = [];
                this.departamentosLoading=false;
                //this.departamentosActividadLoading=false;
            });
        
        },


        //Obtener municipios por departamento
        async obtenerMunicipiosPorDepartamento(departamentosId){
            
            this.ddMunicipiosLoading=true;
           // this.datosSubdivisionTerritorio.municipiosId=null;
          
            await this.$store
            .dispatch(OBTENER_MUNICIPIOS_DEPARTAMENTO, departamentosId) //1 representa al país 1 (Guatemala)
            .then(res => {
                if (res.status === 200) {
                    this.municipios = res.data;
                }
                this.ddMunicipiosLoading=false;
               
            })
            .catch(() => {
                this.ddMunicipiosLoading=false;
                
            });
        },


        //Obtener los tipos de contacto para proyectos CNS (2)
        async obtenerTiposContacto() {
        this.tiposContacto = [];
        this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'TipoContacto/all/1/3'})
            .then(res => {
            if (res.status === 200) {
                this.tiposContacto = res.data;
            }
            })
            .catch(() => {
                this.tiposContacto = [];
            });
        },


        //Obtener la información de contactos del proyecto
        //El tipo se usa para poner la animacion de todo el card (1) o solo la tabla (2)
        async obtenerContactos(proyectoId){

            this.contactos = [];
            this.tableContactosLoading = true;
         
            this.$store
                .dispatch(OBTENER_CONTACTOS, {tipoContacto: 'PROYECTOCNSC', estadoId: 1, id: proyectoId})
                .then(res => {
                    if(res.status === 200) {
                        this.contactos = res.data;
                    }

                     this.tableContactosLoading = false;
                     this.skeletonLoading=false;
                })
                .catch(() => {
                     this.skeletonLoading=false;
                     this.tableContactosLoading=false;
                });

        },


        async registrarContactos(){
            
            this.btnRegistroContactoLoading=true;
            this.datosContacto.tipo = 2;
            this.datosContacto.estadosId= 1;
            this.datosContacto.usuarioCreacion ='admin';
            this.datosContacto.tipoContactoId = this.tipoContactoSeleccionado.id;
            this.datosContacto.proyectoCNSCId = this.id;
           
            await this.$store
            .dispatch(REGISTRAR_CONTACTO, {datos: this.datosContacto})
            .then(res => {
                
                //this.dialogLoaderVisible = false;
                if (res.status === 200) {
                    this.$refs.snackalert.SnackbarShow("success", "Mensaje", res.message);
                    this.obtenerContactos(this.id);             
                    this.resetItems(2);
                } else {
                    this.$refs.snackalert.SnackbarShow("warning", "Alerta", res.message);
                }
                this.btnRegistroContactoLoading=false;
            })
            .catch(error => {
                
                 this.btnRegistroContactoLoading=false;
                 this.$refs.snackalert.SnackbarShow("warning", "Alerta", `Ha ocurrido un error durante el registro. ${error}`
                );
            });
        },


        //Obtener la información de contactos del proyecto cns
        async obtenerDatosSeccion(){

            this.datosItem = {};
            this.cardDatosLoading = true;
         
            this.$store
                .dispatch(OBTENER_DATOS_SECCIONES_PROYECTO, {seccion: 'ContactosProyecto', id: 1})
                .then(() => {
                    this.datosItem = this.$store.state.proyectocns.datosSeccion;
                    this.cardDatosLoading = false;
                    this.condicionDesembolsos.id = this.datosItem.condicionesDesembolsos;
                })
                .catch(() => {
                    this.cardDatosLoading = false;
                });

        },

        //Eliminar contacto
        async eliminarContacto(id){
            this.btnEliminarDisabled=true;
            await this.$store
            .dispatch(ELIMINAR_CONTACTO, id)
            .then(res => {
                
                //this.dialogLoaderVisible = false;
                if (res.status === 200) {
                    this.$refs.snackalert.SnackbarShow("success", "Mensaje", res.message);
                    this.obtenerContactos(this.id);             
                   // this.resetItems();
                } else {
                    this.$refs.snackalert.SnackbarShow("warning", "Alerta", res.message);
                }
                this.btnEliminarDisabled=false;
            })
            .catch(error => {
                
                 this.btnEliminarDisabled=false;
                 this.$refs.snackalert.SnackbarShow("warning", "Alerta", `Ha ocurrido un error durante el registro. ${error}`
                );
            });
        }
    },
    created(){
        this.obtenerTiposContacto();
        this.obtenerContactos(this.id);
        this.obtenerDepartamentos();
     //   this.proyectoId = this.id;
        
    },

    computed: {
        headersContactos() {
            return [
                {
                    text: "Id",
                    //align: "start",
                    sortable: false,
                    value: "id",
                    align: ' d-none'
                },
                {
                    text: "Puesto",
                    align: "start",
                    sortable: true,
                    value: "tipo"
                },
                {
                    text: "Nombre",
                    align: "start",
                    sortable: true,
                    value: "nombre"
                },
                {
                    text: "Correo institucional",
                    align: "start",
                    sortable: true,
                    value: "correo"
                },
                 {
                    text: "Correo personal",
                    align: "start",
                    sortable: true,
                    value: "correo"
                },
                {
                    text: "Estado",
                    align: "start",
                    sortable: true,
                    value: "estado"
                },
                {
                    text: "Acciones",
                    align: "start",
                    sortable: false,
                    value: ""
                }
            ]
        }
    }
}
</script>